import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

const GOOGLE_ANALYTICS_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID || "";

let gaInitialized = false;

const useGoogleAnalytics = () => {
    const location = useLocation();

  useEffect(() => {
    if (!gaInitialized && GOOGLE_ANALYTICS_ID) {
      ReactGA.initialize(GOOGLE_ANALYTICS_ID);
      ReactGA.send("pageview");
      gaInitialized = true;
    }
  }, []);

  useEffect(() => {
    if (gaInitialized) {
      // Enviar un evento de pageview cada vez que cambia la ruta
      ReactGA.send({ hitType: "pageview", page: location.pathname });
    }
  }, [location]); // Escucha los cambios de la ubicación

  return ReactGA;
};

export default useGoogleAnalytics;
