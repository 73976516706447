import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { connect } from "react-redux";

import {
  faCartCircleXmark,
  faMinus,
  faPlus,
  faStaffSnake,
} from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import Modal from "react-modal";
import { Link, useNavigate, useParams } from "react-router-dom";
import "../assets/styles/globalColorPrincipal.css";
import { LazyLoadImage } from "../components/lazyLoadImage";
import Page_message from "../components/Page_message";
import { useThousandSeparator } from "../hooks/useThousandSeparator";
import {
  checked_carrito_lista_compra,
  dec_al_carrito_lista_compra,
  inc_al_carrito_lista_compra,
  inc_peso_carrito_lista_compra,
} from "../redux/actions/shoppingList";
import FullScreenImage from "./../widgets/fullScreenImage";
const customStyles2 = {
  overlay: {
    backgroundColor: "#000000a6",
    zIndex: "10",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    borderRadius: "0 px",
    border: "none",
    background: "none",
    padding: "0px 0px 0px 0px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "none",
    maxHeight: "calc(100% - 32px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
const ItemDetalleListaCompra = ({
  ps_producto,
  ps_object_lista_compra,
  inc_al_carrito_lista_compra,
  dec_al_carrito_lista_compra,
  ps_calcularTotal,
  checked_carrito_lista_compra,
  ps_openModalPeso,
  ps_viewProductoPeso,
  ps_actualizarCantidadCarrito,
  ps_eliminarProduct,
  handleOpenModalUserRegister,
  profile,
}) => {
  const { numberFormatter } = useThousandSeparator();
  const navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [showFullScreen, setShowFullScreen] = useState(false);
  const { ciudadp, sucursalp } = useParams();
  const isUserAuthenticated =
    profile?.Telefono != null && profile?.Telefono !== "";
  const _es_pieza = () => {
    if (ps_producto) {
      return ps_producto.Fraccionado && ps_producto.PesoDefinido ? true : false;
    } else {
      return false;
    }
  };

  const formatNumber = (value) => {
    return numberFormatter.format(value);
  };

  const checkedProducto = async (Producto, nextChecked) => {
    await checked_carrito_lista_compra(Producto, nextChecked);
    await ps_calcularTotal();
  };

  const openModalPesoOrPieza = async (producto) => {
    if (!isUserAuthenticated) {
      handleOpenModalUserRegister();
      return;
    }
    if (_es_peso()) {
      await ps_viewProductoPeso(producto);
      await ps_openModalPeso();
    }
  };

  const decrement_carrito = async () => {
    if (!isUserAuthenticated) {
      handleOpenModalUserRegister();
      return;
    }
    if (_es_peso()) {
      ps_actualizarCantidadCarrito(ps_producto, "decremento", "es_peso");
    } else if (_es_pieza()) {
      let param = await get_url();
      navigate(
        param +
          "/lista-compra/detalle-producto/" +
          ps_object_lista_compra.Id +
          "/" +
          ps_producto.IdProducto
      );
    } else {
      normal("decremento");
    }
    ps_calcularTotal();
  };

  const incremento_carrito = async () => {
    if (!isUserAuthenticated) {
      handleOpenModalUserRegister();
      return;
    }
    if (_es_peso()) {
      ps_actualizarCantidadCarrito(ps_producto, "incremento", "es_peso");
    } else if (_es_pieza()) {
      let param = await get_url();
      navigate(
        param +
          "/lista-compra/detalle-producto/" +
          ps_object_lista_compra.Id +
          "/" +
          ps_producto.IdProducto
      );
    } else {
      normal("incremento");
    }

    ps_calcularTotal();
  };

  const normal = async (operacion) => {
    if (operacion === "incremento") {
      if (ps_producto.Cantidad === 0) {
        await inc_al_carrito_lista_compra(ps_producto);
        await checked_carrito_lista_compra(ps_producto, true);
      } else {
        await inc_al_carrito_lista_compra(ps_producto);
      }
    } else if (operacion === "decremento") {
      if (ps_producto.Cantidad === 1) {
        await dec_al_carrito_lista_compra(ps_producto);
        await checked_carrito_lista_compra(ps_producto, false);
      } else {
        await dec_al_carrito_lista_compra(ps_producto);
      }
    }
    await ps_calcularTotal();
  };

  const _es_peso = () => {
    if (ps_producto) {
      return ps_producto.Fraccionado && !ps_producto.PesoDefinido
        ? true
        : false;
    } else {
      return false;
    }
  };

  const _estimado = () => {
    let result = 0;
    if (_es_pieza()) {
      ps_producto.Pesos.forEach((peso) => {
        result += peso.Piezas * peso.PesoAproxMax;
      });
    } else {
      result = ps_producto.Cantidad;
    }
    return Number(result).toFixed(2);
  };

  const getCantidad = () => {
    const esProductoPeso = _es_peso();
    let cantidad = `${ps_producto.Cantidad} Und`;
    if (esProductoPeso) {
      cantidad = `${(ps_producto.Cantidad * 1000).toFixed(0)}g`;
    }
    return cantidad;
  };

  const getUnidadDeMedida = () => {
    const esProductoPeso = _es_peso();
    const esProductPieza = _es_pieza();
    let unidad = "Und";
    if (esProductoPeso || esProductPieza) {
      unidad = "Kg";
    }
    return unidad;
  };

  const delete_registro = async (idproducto) => {
    let title = "¿Está seguro de quitar el producto de la lista?";
    let resp = await Page_message.delete_register_confirmation(title, "");
    if (resp) {
      ps_eliminarProduct(idproducto);
    }
  };
  const _estimado_precio = (producto) => {
    let result = 0;
    let total_estimado = 0;
    if (_es_pieza()) {
      producto.Pesos.forEach((peso) => {
        result += peso.Piezas * peso.PesoAproxMax;
      });
    } else {
      result = producto.Cantidad;
    }

    let precio_producto = producto.ConOferta
      ? producto.PrecioOferta
      : producto.PrecioVenta;
    total_estimado = result * precio_producto;
    let new_format = formatNumber(total_estimado);
    return new_format;
  };
  const get_url = async () => {
    let url_nav = JSON.parse(localStorage.getItem("url_navigation"));
    if (ciudadp && sucursalp) {
      return "/" + ciudadp + "/" + sucursalp;
    } else if (url_nav) {
      return "/" + url_nav.url_parameters;
    }
  };

  const cantidadProducto = getCantidad();
  const unidadDeMedida = getUnidadDeMedida();
  const pesoEstimado = _estimado();

  const _descuento = () => {
    let resultado = 0;
    if (ps_producto) {
      if (ps_producto.ConOferta) {
        resultado =
          100 - (ps_producto.PrecioOferta * 100) / ps_producto.PrecioOriginal;
      }
    }
    return resultado.toFixed(1);
  };
  const descuento = _descuento();
  return (
    <div className=" flex justify-between p-2 px-2 py-0.5 border-b-2">
      <div className="product_descript w-full grid grid-cols-12 gap-2 md:gap-2">
        <div className="col-span-3 md:col-span-2 bg-white flex justify-center items-center  transition duration-200 ease-in-out transform hover:scale-110 hover:cursor-pointer">
          <div className="relative w-full h-full">
            {ps_producto.ConOferta && (
              <div className="absolute top-4 left-0 z-10 -rotate-45 leading-none">
                <div
                  className={classNames(
                    "py-0.5 px-1.5 text-[8px] md:text-[10px] bg-hiperorange text-white inline-flex items-center gap-1 relative h-[18px] md:h-[20px] rounded-r-sm"
                  )}
                >
                  <span className="font-semibold mr-1 leading-none">
                    -{descuento} %
                  </span>
                  <div
                    className={classNames(
                      "absolute -right-[6px] rotate-45 h-3.5 w-3.5 flex items-center justify-center bg-hiperorange rounded-sm"
                    )}
                  >
                    <div className="w-1.5 h-1.5 bg-white rounded-full"></div>
                  </div>
                </div>
              </div>
            )}
            <>
              <LazyLoadImage
                className="w-full"
                style={{
                  filter: ps_producto.Vigente ? "none" : "grayscale(100%)",
                }}
                src={ps_producto.UrlFoto + "&size=120x120"}
                alt={ps_producto.Descripcion}
                onClick={() => {
                  setIsOpen(true);
                }}
              />
              {ps_producto.EsProductoReceta && (
                <div
                  className="absolute bottom-3 right-3 inline"
                  title="Producto solo vendido bajo receta medica."
                >
                  <FontAwesomeIcon
                    size="2xl"
                    icon={faStaffSnake}
                    className="text-[#17998c]"
                  />
                </div>
              )}
            </>
          </div>
        </div>

        <div className="col-span-6 md:col-span-7 flex flex-col h-100">
          <div className="flex gap-1 flex-col h-full">
            <Link
              to={`/${ciudadp}/${sucursalp}/producto/${ps_producto.IdProducto}/${ps_producto.Descripcion}`}
              className="font-bold text-sm leading-tight md:leading-normal md:text-lg text-black hover:underline hover:text-[#0349ab]"
            >
              {ps_producto.Descripcion}
            </Link>
            <div className="flex flex-col">
              <div className="font-bold m-0 text-black text-sm md:text-base">{`Precio por ${unidadDeMedida}`}</div>
              <div className="flex items-center gap-2">
                <span className="text-sm md:text-base">
                  Bs.{" "}
                  {ps_producto.ConOferta
                    ? formatNumber(ps_producto.PrecioOferta)
                    : formatNumber(ps_producto.PrecioVenta)}
                </span>
                {ps_producto.ConOferta && (
                  <span className="font-semibold  text-zinc-400 line-through text-xs md:text-sm">
                    Bs. {formatNumber(ps_producto.PrecioOriginal)}
                  </span>
                )}
              </div>
            </div>
            <div className="cantidad flex items-end  gap-1 text-white mt-auto">
              {ps_producto.Vigente ? (
                <div className="flex gap-1 md:gap-2">
                  <button
                    className="bg-hiperorange hover:bg-hiperorange_hover flex w-6 h-6 md:h-7 md:w-7 p-2 rounded-md text-white self-center justify-center items-center "
                    style={{
                      cursor:
                        ps_producto.Cantidad === 0 ? "not-allowed" : "pointer",
                    }}
                    onClick={() => decrement_carrito()}
                    disabled={ps_producto.Cantidad === 0}
                  >
                    <FontAwesomeIcon
                      icon={faMinus}
                      className="text-white text-xs"
                    />
                  </button>
                  <div className="flex text-black">
                    <span
                      className="bg-gray-100 rounded-lg py-1 px-1.5 md:py-1 md:px-3 font-bold text-sm md:text-base"
                      style={{
                        cursor: _es_peso() === true ? "pointer" : "default",
                      }}
                      onClick={() => openModalPesoOrPieza(ps_producto)}
                    >
                      {cantidadProducto}
                    </span>
                  </div>

                  <button
                    className="bg-hiperorange hover:bg-hiperorange_hover flex w-6 h-6 md:h-7 md:w-7 p-2 rounded-md text-white self-center justify-center items-center"
                    onClick={() => incremento_carrito()}
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="text-white text-xs"
                    />
                  </button>
                </div>
              ) : (
                <div className="flex gap-2 px-2 py-1 bg-red-500 text-white items-center rounded-full font-bold">
                  <FontAwesomeIcon icon={faCartCircleXmark} size="lg" />
                  <span>Agotado</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-span-3 flex flex-col min-w-20">
          <div className="flex items-center justify-end">
            <div className="round">
              <input
                type="checkbox"
                checked={ps_producto.Checked}
                onChange={(e) => {
                  if (!isUserAuthenticated) {
                    handleOpenModalUserRegister();
                    return;
                  }
                  checkedProducto(ps_producto, e.target.checked);
                }}
                className="checkbox"
                disabled={ps_producto.Vigente ? false : true}
                id={"checkbox" + ps_producto.IdProducto}
              />
              <label
                htmlFor={"checkbox" + ps_producto.IdProducto}
                style={{
                  cursor: ps_producto.Vigente ? "pointer" : "not-allowed",
                  border: ps_producto.Vigente ? "" : "1px solid #8F8F8F",
                }}
              ></label>
            </div>
          </div>
          {(_es_peso() || _es_pieza()) && (
            <div className=" font-bold text-right text-sm md:text-base">
              {`${formatNumber(pesoEstimado)} ${unidadDeMedida}`}
            </div>
          )}
          <div className="font-bold text-right text-sm md:text-base h-full">
            Bs. {_estimado_precio(ps_producto)}
          </div>
          <button
            className="text-hiperorange hover:text-hiperorange_hover underline font-bold text-right text-sm md:text-base"
            onClick={() => {
              if (!isUserAuthenticated) {
                handleOpenModalUserRegister();
                return;
              }
              delete_registro(ps_producto.IdProducto);
            }}
          >
            Eliminar
          </button>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        appElement={document.getElementById("root")}
        onRequestClose={() => {
          setIsOpen(false);
        }}
        style={customStyles2}
        contentLabel="Example Modal"
        key={"keyModal"}
      >
        <FullScreenImage
          imagenes={[
            {
              original: ps_producto.UrlFoto + "&size=900x900",
              loading: "eager",
              originalWidth: 900,
              originalHeight: 900,
              originalAlt: ps_producto.Descripcion,
            },
          ]}
          tiene_varias_imagenes={"no"}
          imagen={ps_producto.UrlFoto}
          onClose={() => {
            setIsOpen(false);
          }}
        ></FullScreenImage>
      </Modal>
    </div>
  );
};
const mapStateToProps = (state) => ({
  carrito_lista_compra: state.Shopping_list.carrito_lista_compra,
  totalQuantities: state.Shopping_list.totalQuantities,
  profile: state.Profile.profile,
});
export default connect(mapStateToProps, {
  inc_al_carrito_lista_compra,
  dec_al_carrito_lista_compra,
  checked_carrito_lista_compra,
  inc_peso_carrito_lista_compra,
})(ItemDetalleListaCompra);
