import {
  faBan,
  faChevronLeft,
  faDownload,
  faMoped,
  faRotate,
  faThumbsUp,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  setPedidosUsuarioFireb,
  list_pedidos,
} from "../../src/redux/actions/pedido";

import { onValue, ref, off } from "@firebase/database";
import "../assets/styles/inicio.scss";
import { database } from "../Firebase";

export const EstadoPedidoSmall = ({
  pedido,
  profile,
  showtodopedidos,
  list_pedidos,
}) => {
  const [showItem, setShowItem] = useState(true);
  const [show, setShow] = useState(false);
  const [pedido_firebase, setPedido_firebase] = useState(null);
  const [icon, setIcon] = useState(null);
  const [iconColor, setIconColor] = useState(null);
  const [statusText, setStatusText] = useState("");

  useEffect(() => {
    // if (pedido_firebase) {
    const estado_pedido_id = pedido_firebase
      ? pedido_firebase.EstadoPedidoCliente.Id
      : pedido.EstadoPedidoCliente.Id;
    if (
      !!pedido_firebase &&
      (estado_pedido_id === 7 || estado_pedido_id === 6)
    ) {
      setTimeout(() => {
        list_pedidos();
      }, 2000);
    }
    const { icon, color, text } = getIconColorAndText(estado_pedido_id);
    setIcon(icon);
    setIconColor(color);
    setStatusText(text);
    // }
  }, [pedido_firebase, pedido]);

  useEffect(() => {
    let unsubscribe;
    async function fetchData() {
      try {
        let tipo_user = localStorage.getItem("user");
        if (
          profile !== null &&
          Object.keys(pedido).length !== 0 &&
          tipo_user !== '"anonimo"'
        ) {
          const databaseRef = ref(database, "pedidos/" + profile.IdUsuario);

          const listener = (snapshot) => {
            const data = snapshot.val();
            search_pedido(data, pedido.NroPedido);
          };

          onValue(databaseRef, listener);
          unsubscribe = () => off(databaseRef, listener);
        }
      } catch (e) {
        console.error(e);
      }
    }

    fetchData();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [profile, pedido]);

  useEffect(() => {
    setShow(showtodopedidos);
  }, [showtodopedidos]);

  const search_pedido = (pedidos, pedido_id) => {
    if (pedidos) {
      const pedidos_aux = Object.values(pedidos);
      pedidos_aux.forEach((el) => {
        if (el.NroPedido === pedido_id) {
          setPedido_firebase(el);
        }
      });
    }
  };

  const getIconColorAndText = (pedido_id) => {
    switch (pedido_id) {
      case 1:
        return { icon: faDownload, color: "#ed6a0f", text: "Solicitado" };
      case 3:
        return { icon: faRotate, color: "#ed6a0f", text: "En proceso" };
      case 4:
      case 5:
        return { icon: faMoped, color: "#ed6a0f", text: "En camino" };
      case 6:
        return { icon: faThumbsUp, color: "#ed6a0f", text: "Entregado" };
      case 7:
        return { icon: faBan, color: "#ed6a0f", text: "Cancelado" };
      default:
        return { icon: null, color: null, text: "" };
    }
  };

  const namefuncion = () => {
    setShow((show) => !show);
  };
  const formatDateString = (dateString) => {
    const parts = dateString.split("/");
    return (
      <>
        {parts[0]}
        <span className="text-white">/</span>
        {parts[1]}
        <span className="text-white">/</span>
        {parts[2]}
      </>
    );
  };
  return (
    <div>
      {show ? (
        <div className="estilo_radio_estado_pedido_panel_principal cajaDetPed transition duration-200 ease-in-out transform hover:scale-105">
          <div
            className="estilo_radio_estado_pedido_panel_principal_cabecera cabecera_estado_pedido_panel_principal flex flex-col justify-center items-start text-start"
            style={{ backgroundColor: "#ed6a0f" }}
          >
            <div className="style_header_text">
              Pedido:&nbsp;
              {pedido.NroPedido} <br />
            </div>
            <div className="style_header_text">
              Entrega:&nbsp;{formatDateString(pedido.FechaEntrega)}
            </div>
          </div>
          <div className="body_panel_state_pedido flex flex-col justify-center items-center">
            {icon && (
              <div className="flex flex-col justify-center items-center">
                <div
                  className="circulo_icono_state_pedidos flex justify-center items-center"
                  style={{ backgroundColor: iconColor }}
                >
                  <FontAwesomeIcon
                    icon={icon}
                    style={{
                      height: "20px",
                      color: "#fff",
                      fontSize: "20px",
                    }}
                  />
                </div>
                <span
                  style={{ color: "#acacac", textAlign: "center" }}
                  className="estilo_size_font_icon"
                >
                  {statusText}
                </span>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="container_panel_oculto">
          <div className="container_oculto estilo_radio_estado_pedido_panel_principal">
            <div
              className="estilo_radio_estado_pedido_panel_principal_cabecera cabecera_estado_pedido_panel_principal"
              style={{ backgroundColor: iconColor }}
            >
              <FontAwesomeIcon
                className="style_icon_rigth_header"
                onClick={namefuncion}
                icon={faChevronLeft}
                style={{
                  height: "20px",
                  paddingRight: "10px",
                  paddingLeft: "10px",
                }}
              />
              <label style={{ color: "#000" }}>{show}</label>
            </div>
            <div className="body_panel_state_pedido"></div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  pedidos_firebase: state.Pedido.pedidos_firebase,
});

export default connect(mapStateToProps, {
  setPedidosUsuarioFireb,
  list_pedidos,
})(EstadoPedidoSmall);
