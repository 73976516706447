import React, { useState, useEffect } from "react";
import ItemFavorito from "./item_favorito";
import { get_lista_compra } from "../redux/actions/shoppingList";
import { connect } from "react-redux";
import "../assets/styles/mycart.scss";
import { useNavigate, useParams } from "react-router-dom";
import ButtonText from "./button_text";
import {
  get_producto_item,
  limpiar_item_producto,
} from "../redux/actions/producto";
import {
  limpiar_data_lista_compra,
  get_detalle_lista_compra,
} from "../redux/actions/shoppingList";
import { set_cambio_favorito } from "../redux/actions/carrito";
import Alert from "../components/Swalert";
import ButtonClose from "./button_close";
import "../assets/styles/responsive.scss";
import LoaderSpinner from "./loaderSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReceipt } from "@fortawesome/pro-solid-svg-icons";
import Modal from "react-modal";
import ModalAddListaCompra from "../containers/ListaCompra/Partials/ModalAddListaCompra";

const customStylesFondo = {
  overlay: {
    backgroundColor: "#000000a6",
    zIndex: "32",
  },
  content: {
    borderRadius: "10px",
    padding: "0px",
    border: "0px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "calc(100% - 32px)",
    overflow: "hidden",
  },
};

const FavoritoMycart = ({
  get_lista_compra,
  shopping_list,
  idProducto,
  IdSucursal,
  cantidad,
  producto,
  pesos = [],
  get_producto_item,
  limpiar_item_producto,
  delete_producto = true,
  limpiar_data_lista_compra,
  set_cambio_favorito,
  carrito_favorito,
  ps_closeModalFavorito,
  get_detalle_lista_compra,
}) => {
  const navigate = useNavigate();
  const { ciudadp, sucursalp, IdListaCompra } = useParams();
  const [listaCompra, setListaCompra] = useState(null);
  const [modalAddListPurchase, setAddListPurchase] = React.useState(false);
  const startAddListPurchase = () => setAddListPurchase(true);
  const endAddListPurchase = () => setAddListPurchase(false);

  useEffect(() => {
    get_lista_compra(IdSucursal);
    get_producto_item(IdSucursal, idProducto);
  }, [IdSucursal]);

  useEffect(() => {
    if (producto && shopping_list) {
      if (producto.EnListadoCliente) {
        let aux = shopping_list.map((lista) => {
          let item = producto.IdListadosCompra.find(
            (idLista) => idLista === lista.Id
          );
          if (item) {
            return {
              ...lista,
              favorito: true,
            };
          } else {
            return {
              ...lista,
              favorito: false,
            };
          }
        });
        setListaCompra(aux);
      } else {
        setListaCompra(shopping_list);
      }
    }
  }, [producto, shopping_list]);
  // useEffect(() => {
  //   if (delete_producto) {
  //     return () => limpiar_item_producto();
  //   }
  // }, []);
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
      // limpiar_data_lista_compra();
      if (carrito_favorito) {
        set_cambio_favorito(false);
      }
    };
  }, []);

  const reload_list = (idProducto, IdSucursal, IdLista) => {
    get_producto_item(IdSucursal, idProducto);
    if (IdListaCompra == IdLista) {
      const parametro = { Id: IdLista, Sucursal: IdSucursal };
      get_detalle_lista_compra(parametro);
    }
  };
  const new_lista = (e) => {
    startAddListPurchase();
  };
  return (
    <>
      <Alert></Alert>
      <div className="w-full flex flex-col h-full md:max-h-[600px]">
        <div className="w-full bg-white rounded-lg md:rounded-lg h-full overflow-hidden">
          <div className="flex flex-col h-full">
            <div className="bg-hiperorange w-100 relative text-white flex items-center justify-center h-[52px]  content_title">
              <h2 className="p-0 m-0 title_carrito_responsivo">
                Mis listas de compras
              </h2>
              <div className="absolute right-2 ">
                <ButtonClose
                  className="text-white bg-transparent"
                  onClick={() => ps_closeModalFavorito()}
                />
              </div>
            </div>
            <label
              className="text-black px-1   mb-2"
              style={{ fontSize: "15px", fontWeight: "600" }}
              htmlFor=""
            >
              Añadir este producto a:
            </label>
            <div className="bg-white overflow-y-auto flex flex-col h-full gap-2 p-2">
              {listaCompra ? (
                <>
                  {producto && listaCompra.length > 0 ? (
                    listaCompra.map((item_shopping_list) => (
                      <ItemFavorito
                        ps_shopping_list={item_shopping_list}
                        key={item_shopping_list.Id}
                        idProducto={idProducto}
                        IdSucursal={IdSucursal}
                        cantidad={cantidad}
                        pesos={pesos}
                        peso_definitivo={producto.PesoDefinido}
                        favorito={item_shopping_list.favorito}
                        recargar_listado={reload_list}
                        producto={producto}

                      ></ItemFavorito>
                    ))
                  ) : (
                    <div className="flex flex-col gap-4 justify-center items-center mt-2 min-h-60 text-gray-400">
                      <FontAwesomeIcon icon={faReceipt} size="6x" />
                      <div className="text-xl">
                        No tienes listas de compras para mostrar
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className="flex justify-center text-black text-center pb-2">
                  <LoaderSpinner bool={true} />
                </div>
              )}
            </div>

            <div className="text-center pt-3 pb-4 px-4 text-white sticky bottom-0">
              <ButtonText
                label="Nueva lista"
                className="bg-hiperorange hover:bg-hiperorange_hover"
                onClick={() => new_lista()}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalAddListPurchase}
        onRequestClose={endAddListPurchase}
        ariaHideApp={false}
        className="fixed inset-0 flex items-center justify-center p-2 md:p-4 w-full md:max-w-sm  md:mx-auto focus-visible:outline-none"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-50"
        contentLabel="shopping"
      >
        {/* container content */}
        <ModalAddListaCompra
          ps_closeModalAdd={endAddListPurchase}
          IdSucursal={IdSucursal}
        ></ModalAddListaCompra>
      </Modal>
    </>
  );
};
const mapStateToProps = (state) => ({
  shopping_list: state.Shopping_list.shopping_list,
  producto: state.Producto.producto,
  carrito_favorito: state.Carrito.carrito_favorito,
});

export default connect(mapStateToProps, {
  get_lista_compra,
  get_producto_item,
  limpiar_item_producto,
  limpiar_data_lista_compra,
  set_cambio_favorito,
  get_detalle_lista_compra,
})(FavoritoMycart);
