import useFacebookPixel from "./facebook-pixel/use-facebook-pixel";
import useGoogleAnalytics from "./google-analytics/use-google-analytics";
import { useBuildTrackingPurchaseDataForFacebookPixel } from "./tracking/use-build-tracking-purchase-data-for-facebook-pixel";
import { useBuildTrackingPurchaseDataForGoogleAnalytics } from "./tracking/use-build-tracking-purchase-data-for-google-analytics";

export const DEFAULT_CURRENCY = "BOB";

export enum ContentTypes {
  PRODUCT = "product",
}

export interface AddToWishlistData {
  idProducto: string;
  cantidad: number;
  descripcion: string;
  precio: number;
}

export interface AddToCartData {
  idProducto: string;
  descripcion: string;
  subtotal: number;
  cantidad: number;
  precio: number;
}

export interface ProductData {
  idProducto: string;
  precio: number;
  descripcion: string;
}

export function useTrackEvents() {
  const { buildTrackingPurchaseDataForFacebookPixel } =
    useBuildTrackingPurchaseDataForFacebookPixel();

  const { buildTrackingPurchaseDataForGoogleAnalytics } =
    useBuildTrackingPurchaseDataForGoogleAnalytics();
  const pixel = useFacebookPixel();
  const google = useGoogleAnalytics();

  const trackSearchEvent = (searchTerm: string) => {
    const searchQuery = searchTerm.trim();
    if (pixel) {
      pixel.trackEvent("Search", {
        search_string: searchQuery,
        currency: DEFAULT_CURRENCY,
      });
    }
    if (google) {
      google.event("search", {
        search_term: searchQuery,
      });
    }
  };

  const trackAddToWishistEvent = ({
    idProducto,
    cantidad,
    descripcion,
    precio,
  }: AddToWishlistData) => {
    if (pixel) {
      pixel.trackEvent("AddToWishlist", {
        currency: DEFAULT_CURRENCY,
        content_ids: [idProducto],
        contents: [
          {
            id: idProducto,
            quantity: cantidad,
          },
        ],
        value: precio,
      });
    }
    if (google) {
      google.event("add_to_wishlist", {
        currency: DEFAULT_CURRENCY,
        value: precio,
        items: [
          {
            item_id: idProducto,
            item_name: descripcion,
          },
        ],
      });
    }
  };

  const trackAddToCartEvent = ({
    idProducto,
    descripcion,
    subtotal,
    cantidad,
    precio,
  }: AddToCartData) => {
    if (pixel) {
      pixel.trackEvent("AddToCart", {
        content_ids: [idProducto],
        content_type: ContentTypes.PRODUCT,
        value: subtotal,
        currency: DEFAULT_CURRENCY,
        contents: [
          {
            id: idProducto,
            quantity: cantidad,
          },
        ],
      });
    }
    if (google) {
      google.event("add_to_cart", {
        currency: DEFAULT_CURRENCY,
        value: subtotal,
        items: [
          {
            item_id: idProducto,
            item_name: descripcion,
            quantity: cantidad,
            price: precio,
          },
        ],
      });
    }
  };

  const trackPurchaseEvent = (
    purchaseData: any,
    transaction_id: string,
    shipping: number
  ) => {
    const trackingPurchaseDataForFP =
      buildTrackingPurchaseDataForFacebookPixel(purchaseData);
    const trackingPurchaseDataForGA =
      buildTrackingPurchaseDataForGoogleAnalytics(
        purchaseData,
        transaction_id,
        shipping
      );
    if (pixel) {
      pixel.trackEvent("Purchase", trackingPurchaseDataForFP);
    }
    if (google) {
      google.event("purchase", trackingPurchaseDataForGA);
    }
  };

  const trackProductEvent = ({
    idProducto,
    precio,
    descripcion,
  }: ProductData) => {
    if (pixel) {
      pixel.trackEvent("ViewContent", {
        content_ids: [idProducto],
        content_type: "product",
        value: precio,
        currency: DEFAULT_CURRENCY,
      });
    }
    if (google) {
      google.event("view_item", {
        currency: DEFAULT_CURRENCY,
        value: precio,
        items: [
          {
            item_id: idProducto,
            item_name: descripcion,
            price: precio,
          },
        ],
      });
    }
  };
  return {
    trackSearchEvent,
    trackAddToWishistEvent,
    trackAddToCartEvent,
    trackPurchaseEvent,
    trackProductEvent,
  };
}
