import {
  faFacebook,
  faGoogle,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { faMessage } from "@fortawesome/pro-solid-svg-icons";
import { useEffect, useMemo, useState } from "react";
import { parsePhoneNumber } from "react-phone-number-input";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import "react-phone-number-input/style.css";
import "../../../assets/styles/globalColorPrincipal.css";
import "../../../assets/styles/mystyle.scss";
import "../../../assets/styles/stylePageGlobals.scss";
// import "../../../containers/auth/input-phone.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm, Controller } from "react-hook-form";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import SocialLogin from "../../../containers/auth/SocialLogin";
import {
  TIPO_ACCESO_REDES_SOCIALES_NAME,
  TIPO_ACCESO_REDES_SOCIALES_VALUES,
} from "../../../containers/auth/constants";
import { useGetBackLink } from "../../../hooks/useGetBackLink";
import { useGetSucursalTipoServicioBySlug } from "../../../hooks/useGetSucursalTipoServicioBySlug";
import { useGetSucursalMasCercana } from "../../../hooks/useGetSucursalesCercanas";
import { useGetSucursalesMasCercanaPorTipo } from "../../../hooks/useGetSucursalesMasCercanaPorTipo";
import {
  codigo_acceso,
  get_prefijos,
  get_token,
} from "../../../redux/actions/auth";
import { info_session } from "../../../redux/actions/profile";
import ButtonClose from "../../../widgets/button_close";
import ButtonIcon from "../../../widgets/button_icon";
import LoaderSpinner from "../../../widgets/loaderSpinner";
import Alert from "../../Swalert";
import slug from "../../Utils/slug";
import {
  defaultCountries,
  parseCountry,
  PhoneInput,
  buildCountryData,
} from "react-international-phone";
import "react-international-phone/style.css";
const Registro_user = ({
  array_prefijo,
  peticion,
  loading,
  sendAcceso,
  get_prefijos,
  codigo_acceso,
  profile,
  datoLogin,
  get_token,
  access_token,
  ps_posicion,
  ps_endModalRegistro,
  socialUserData,
  sucursales,
  direccion,
  ciudades,
}) => {
  const { getSucursalesCercanas, getSucursalPorDefecto } =
    useGetSucursalMasCercana();
  const { backLink, searchParams } = useGetBackLink();

  const params = useParams();
  const { ciudadp, sucursalp } = params;
  const [currentSocialLogin, setCurrentSocialLogin] = useState(null);
  const [canalEnvio, setCanalEnvio] = useState([]);
  const [formData, setFormData] = useState({
    telefono: "",
    prefijo: "",
    TipoEnvioAcceso: "",
  });
  const [minLength, setMinLength] = useState("8");
  const {
    // register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",

    defaultValues: {
      telefono: {
        telefono: "",
        prefijo: "",
        TipoEnvioAcceso: "",
      },
    },
  });
  useEffect(() => {
    if (array_prefijo.length === 0) {
      get_prefijos();
    }
  }, [array_prefijo]);
  // useEffect(() => {
  //   if (array_prefijo.length > 0) {
  //     let countries = array_prefijo.map((p) => {
  //       return p.ISO2;
  //     });
  //     let seleccionado = array_prefijo.find((pre) => pre.NumeroPrefijo === 591);
  //     setCanalEnvio(seleccionado.IdCanalesEnvio);
  //     setPaises(countries);
  //     SetCountrySelected(seleccionado);
  //     establecerMinLength(seleccionado.Mask);
  //   }
  // }, [array_prefijo]);
  // useEffect(() => {
  //   if (value && array_prefijo.length > 0) {
  //     let numero = value.replace(/[^0-9]+/g, "");
  //     let seleccionado = array_prefijo.find(
  //       (pre) => Number(pre.NumeroPrefijo) === Number(numero)
  //     );
  //     if (seleccionado !== undefined) {
  //       setCanalEnvio(seleccionado.IdCanalesEnvio);
  //     }
  //   }
  // }, [value]);

  useEffect(() => {
    if (sendAcceso) {
      ps_posicion(2);
    }
  }, [sendAcceso]);

  const { getSucursalTipoServicioBySlug } = useGetSucursalTipoServicioBySlug();
  const { getSucursalesMasCercanaPorTipo } =
    useGetSucursalesMasCercanaPorTipo();

  useEffect(() => {
    if (
      profile &&
      profile.Telefono != "" &&
      sucursales?.length > 0 &&
      ciudades?.length > 0
    ) {
      const sucursalPorDefecto = getSucursalPorDefecto(sucursales);
      let sucursalCliente = null;
      if (profile.Ubicaciones.length > 0) {
        const direccionActual = profile.Ubicaciones.find(
          (ubicacion) => ubicacion.EsPrincipal
        );
        if (direccionActual) {
          const marketCercanos = getSucursalesCercanas(
            sucursales,
            direccionActual
          );
          const previousSucursalTipoServico =
            getSucursalTipoServicioBySlug(sucursalp);

          sucursalCliente = getSucursalesMasCercanaPorTipo(
            marketCercanos,
            previousSucursalTipoServico
          );
          if (!sucursalCliente) {
            sucursalCliente = sucursalPorDefecto;
          }
        }
      } else {
        sucursalCliente = sucursalPorDefecto;
      }
      const ciudad = ciudades.find(
        (pre) => parseInt(pre.IdRegion) === parseInt(sucursalCliente.idRegion)
      );
      let sucursal_slug = slug.data_slug(sucursalCliente.Descripcion);
      let ciudad_slug = slug.data_slug(ciudad.Nombre);
      const sucursalUrl = `/${ciudad_slug}/${sucursal_slug}`;
      if (sucursalp !== sucursal_slug) {
        window.location.href = `${sucursalUrl}${backLink}${searchParams}`;
      }
      ps_endModalRegistro();
    }
  }, [profile, sucursales, ciudades, sucursalp, backLink, searchParams]);

  const is_whatsapp = () => {
    return canalEnvio.find((c) => c === 1);
  };
  const is_sms = () => {
    return canalEnvio.find((c) => c === 2);
  };

  const onSubmit = async (data) => {
    const parsedPhoneNumber = parsePhoneNumber(data.telefono.telefono);

    formData.telefono = parsedPhoneNumber.nationalNumber;
    formData.prefijo = data.telefono.prefijo.country.dialCode;

    await codigo_acceso(
      formData.telefono,
      Number(formData.prefijo),
      formData.TipoEnvioAcceso
    );
  };
  const tipoWhatsapp = (e) => {
    formData.TipoEnvioAcceso = 1;
  };
  const tipoSms = (e) => {
    formData.TipoEnvioAcceso = 2;
  };

  // const onChangeValue = (prefijo) => {
  //   if (prefijo !== undefined) {
  //     let seleccionado = array_prefijo.find((pre) => pre.ISO2 == prefijo);
  //     if (seleccionado !== undefined) {
  //       SetCountrySelected(seleccionado);
  //       establecerMinLength(seleccionado.Mask);
  //     }
  //   }
  // };

  const establecerMinLength = (mask) => {
    let res = mask.replace(/\D/g, "");
    setMinLength(res.length);
  };

  const currentSocialLoginIcon = useMemo(() => {
    switch (currentSocialLogin) {
      case TIPO_ACCESO_REDES_SOCIALES_VALUES.GOOGLE:
        return faGoogle;

      case TIPO_ACCESO_REDES_SOCIALES_VALUES.FACEBOOK:
        return faFacebook;
      default:
        return null;
    }
  }, [currentSocialLogin]);

  const countries = defaultCountries
    .filter((country) => {
      const countryData = parseCountry(country);
      return array_prefijo
        ?.map((prefijo) => prefijo.ISO2.toLowerCase())
        .includes(countryData.iso2);
    })
    .map((country) => {
      const countryData = parseCountry(country);
      const releatedCountry = array_prefijo.find(
        (prefijo) => prefijo.ISO2.toLowerCase() === countryData.iso2
      );
      const newCountryData = {
        ...countryData,
        format: releatedCountry.Mask.replace(/9/g, "."),
        name: releatedCountry.Nombre,
      };

      return buildCountryData(newCountryData);
    });
  return (
    <>
      <Alert></Alert>
      <div className="bg-white rounded-lg max-w-full md:max-w-sm">
        <div className="bg-hiperorange rounded-t-lg w-100 relative text-white flex items-center justify-center h-[52px] content_title">
          <h2 className="p-0 m-0 title_carrito_responsivo">Ingresar</h2>
          <div className="absolute right-2 ">
            <ButtonClose
              disabled={!!socialUserData}
              className="text-white bg-transparent"
              onClick={() => ps_endModalRegistro()}
            />
          </div>
        </div>
        <div className="w-full">
          {currentSocialLogin === null || !!socialUserData ? (
            <div className="h-[auto] max-h-full p-3">
              <div className="flex justify-center mb-1">
                <LoaderSpinner bool={peticion} />
              </div>
              <form
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
                className="flex flex-col justify-center m-0"
              >
                <div className="flex justify-center"></div>
                <div className="box-input w-full">
                  <div className="centenido_form">
                    <label className="mb-2 text-center">
                      {!socialUserData
                        ? "Para disfrutar de todas las funcionalidades de nuestra aplicación y obtener una mejor experiencia, por favor, inicia sesión verificando tu número de teléfono."
                        : `
Para disfrutar de todas las funcionalidades de nuestra aplicación y obtener una mejor experiencia, por favor verifica tu número de teléfono para completar la creación de tu cuenta mediante correo de ${TIPO_ACCESO_REDES_SOCIALES_NAME[currentSocialLogin]}.`}
                    </label>

                    <label htmlFor="phone" className="block font-bold ">
                      Número de celular
                    </label>
                    <div className="mt-1">
                      <div className="w-full flex gap-1">
                        <div className="w-full relative">
                          {array_prefijo?.length > 0 && (
                            <Controller
                              control={control}
                              name="telefono"
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                  <PhoneInput
                                    autoFocus
                                    forceDialCode
                                    inputClassName="w-full border-0 text-base"
                                    className="w-full rounded-md border-1 border-gray-400 py-2 px-3 focus-within:outline-none focus-within:ring focus-within:ring-hiperorange_claro focus-within:border-hiperorange placeholder:text-gray-500"
                                    countrySelectorStyleProps={{
                                      buttonClassName: "border-0"
                                    }}
                                    name="telefono"
                                    value={value.telefono}
                                    onChange={(phone, country) => {
                                      let seleccionado = array_prefijo.find(
                                        (pre) =>
                                          Number(pre.NumeroPrefijo) ===
                                          Number(country.country.dialCode)
                                      );
                                      if (seleccionado !== undefined) {
                                        setCanalEnvio(
                                          seleccionado.IdCanalesEnvio
                                        );
                                      }
                                      establecerMinLength(seleccionado.Mask);
                                      onChange({
                                        telefono: phone,
                                        prefijo: country,
                                        TipoEnvioAcceso: "",
                                      });
                                    }}
                                    countries={countries}
                                    defaultCountry="bo"
                                  />
                                // </div>
                              )}
                              rules={{
                                validate: {
                                  telefonoRequired: (telefonoData) => {
                                    const phoneNumber =
                                      telefonoData.telefono.replace(
                                        `+${telefonoData?.prefijo?.country?.dialCode}`,
                                        ""
                                      );

                                    return (
                                      phoneNumber.trim() !== "" ||
                                      "El campo teléfono es requerido"
                                    );
                                  },
                                  telefonoMinLength: (telefonoData) => {
                                    const phoneNumber =
                                      telefonoData.telefono.replace(
                                        `+${telefonoData?.prefijo?.country?.dialCode}`,
                                        ""
                                      );
                                    return (
                                      phoneNumber.length >= minLength ||
                                      `El campo Número de celular debe tener ${minLength} dígitos`
                                    );
                                  },
                                  canalEnvioRequired: (telefonoData) => {
                                    return (
                                      canalEnvio.length > 0 ||
                                      `No hay un canal de envío habilitado para este país`
                                    );
                                  },
                                },
                              }}
                            />
                          )}
                          {errors.telefono && (
                            <div className="text-red-600 text-sm leading-tight p-1">
                              {errors.telefono.message}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 mt-2 text-white">
                    {canalEnvio.length > 0 ? (
                      <>
                        {is_whatsapp() && (
                          <ButtonIcon
                            type="submit"
                            icon={faWhatsapp}
                            label="Recibir código por WhatsApp"
                            principal={false}
                            onClick={(e) => tipoWhatsapp()}
                            className={
                              "text-white bg-hiperorange hover:bg-hiperorange_hover"
                            }
                          />
                        )}
                        {is_sms() && (
                          <ButtonIcon
                            type="submit"
                            icon={faMessage}
                            principal={false}
                            label="Recibir código por SMS"
                            onClick={() => tipoSms()}
                            className={
                              "text-white bg-hiperorange hover:bg-hiperorange_hover"
                            }
                          />
                        )}
                      </>
                    ) : (
                      <div className="text-lg font-semibold text-gray-600 text-center">
                        País deshabilitado
                      </div>
                    )}
                  </div>
                </div>
              </form>
              {!socialUserData && (
                <>
                  <div className="relative flex py-4 items-center">
                    <div className="flex-grow border-t border-gray-400"></div>
                    <span className="flex-shrink mx-4 text-gray-400">
                      o también
                    </span>
                    <div className="flex-grow border-t border-gray-400"></div>
                  </div>
                  <SocialLogin
                    closeModal={ps_endModalRegistro}
                    handleSetCurrentSocialLogin={setCurrentSocialLogin}
                  />
                </>
              )}
            </div>
          ) : (
            <div className="flex flex-col w-[24rem] justify-center p-4 h-60">
              <LoaderSpinner bool={!!currentSocialLogin} />
              <div className="flex gap-2 justify-center items-center">
                <div>{`Iniciando sesión con correo de ${TIPO_ACCESO_REDES_SOCIALES_NAME[currentSocialLogin]}`}</div>
                {currentSocialLoginIcon && (
                  <FontAwesomeIcon
                    icon={currentSocialLoginIcon}
                    size="xl"
                    className="text-hiperorange"
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  loading: state.Auth.loading,
  datoLogin: state.Auth.datoLogin,
  sendAccesoAnonimo: state.Auth.sendAccesoAnonimo,
  authenticated: state.Auth.authenticated,
  profile: state.Profile.profile,
  access_token: state.Auth.access_token,
  peticion: state.Peticion.peticion,
  array_prefijo: state.Auth.array_prefijo,
  sendAcceso: state.Auth.sendAcceso,
  socialUserData: state.Auth.socialUserData,
  sucursales: state.BranchOffice.branch_offices,
  direccion: state.Location.direccion,
  ciudades: state.BranchOffice.ciudades,
});

export default connect(mapStateToProps, {
  get_prefijos,
  codigo_acceso,
  get_token,
  info_session,
})(Registro_user);
