import { useEffect, useState } from "react";
import { FacebookPixel } from "react-use-facebook-pixel";
const PIXEL_ID = process.env.REACT_APP_FACEBOOK_PIXEL_ID;
//const ENABLE_INIT = true;
const ENABLE_INIT = process.env.REACT_APP_ENVIRONMENT === "production";
const ENABLE_DEBUG = process.env.REACT_APP_ENVIRONMENT !== "production";

let facebookPixelSingleton: FacebookPixel | null = null;

const useFacebookPixel = () => {
  const [facebookPixel, setFacebookPixel] = useState<FacebookPixel | null>(
    null
  );

  useEffect(() => {
    if (!facebookPixelSingleton) {
      const initializeFacebookPixel = async () => {
        const pixel = new FacebookPixel({
          pixelID: PIXEL_ID || "",
          debug: ENABLE_DEBUG,
        });

        pixel.init({});
        pixel.trackEvent("PageView");

        facebookPixelSingleton = pixel;
        setFacebookPixel(pixel);
      };

      if (ENABLE_INIT) {
        initializeFacebookPixel();
      }
    } else {
      if (!facebookPixel) {
        setFacebookPixel(facebookPixelSingleton);
      }
    }
  }, []);

  return facebookPixel;
};

export default useFacebookPixel;
