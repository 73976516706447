import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useSearchTerm } from "../hooks/useSearchTerm";
import { useTrackEvents } from "../modules/track-events/ui/hooks/use-track-events";

const BuscadorGeneral = () => {
  const { trackSearchEvent } = useTrackEvents();

  const { searchTerm: search } = useSearchTerm();
  const [filtro, setFiltro] = useState(search || "");
  const { ciudadp, sucursalp } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (search) {
      setFiltro(search);
    } else {
      setFiltro("");
    }
  }, [search]);

  const enter_opcion = (event) => {
    event.preventDefault();
    let url_nav = JSON.parse(localStorage.getItem("url_navigation"));
    const searchLink = `/busqueda-producto/${
      !!filtro ? `?s=${filtro.trim()}` : ""
    }`;

    trackSearchEvent(filtro.trim());

    if (ciudadp && sucursalp) {
      navigate(`/${ciudadp}/${sucursalp}${searchLink}`);
    } else if (url_nav) {
      navigate(`/${url_nav.url_parameters}${searchLink}`);
    }
  };
  const handleChange = (event) => {
    const { value } = event.target;
    setFiltro(value);
    save_text_filtro(value);
  };
  function save_text_filtro(text) {
    localStorage.setItem("filter_g", text);
  }
  return (
    <div className="w-100">
      <form
        onSubmit={enter_opcion}
        className="relative flex w-full flex-wrap items-stretch "
      >
        <input
          type="text"
          value={filtro}
          onChange={(e) => handleChange(e)}
          placeholder="¿QUÉ ESTAS BUSCANDO?"
          className="w-100 py-2 placeholder-slate-400 text-slate-600 relative  bg-gray-200 rounded-lg text-sm border-1 border-gray-500  focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange pl-2 pr-11"
          style={{ paddingRight: "2rem", paddingLeft: "0.5rem" }}
        />
        <button
          onClick={enter_opcion}
          type="submit"
          className="z-10 absolute right-0 flex items-center justify-center w-8 h-full bg-hiperorange rounded-r-lg text-white"
        >
          <FontAwesomeIcon icon={faSearch} />
        </button>
      </form>
    </div>
  );
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(BuscadorGeneral);
