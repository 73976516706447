import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faCartCircleXmark,
  faHeart,
  faMinus,
  faPlus,
  faStaffSnake,
  faTag,
  faTrashCan,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FreeMode, Navigation, Pagination, Thumbs } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../src/assets/styles/productoCarousel.scss";
import "../../src/assets/styles/seccionResponsive.scss";
import ImageZoom from "../components/ImageZoom/ImageZoom";
import { ZoomProvider } from "../components/ImageZoom/ZoomContext";
import ZoomedImage from "../components/ImageZoom/ZoomedImage";
import { LazyLoadImage } from "../components/lazyLoadImage";
import RegistroInit from "../components/modal/registroUser/registro_init";
import Sucursal from "../components/modal/sucursal";
import {
  DEFAULT_KEYWORDS,
  KEYWORD_BY_SERVICE_TYPE,
} from "../components/SEOComponents/HomeSEO";
import { ProductSEO } from "../components/SEOComponents/ProductSEO";
import Alert from "../components/Swalert";
import convertSlug from "../components/Utils/slug";
import Layout from "../hocs/Layout";
import { useBreadcrumbListJSONLd } from "../hooks/richSnippets/useBreadcrumbListJSONLd";
import { useProductJSONLd } from "../hooks/richSnippets/useProductJSONLd";
import { useBreadcrumbs } from "../hooks/useBreadcrumbs";
import { useGetCurrentUrl } from "../hooks/useGetCurrentUrl";
import { useGetSucursalCiudad } from "../hooks/useGetSucursalCiudad";
import { useIsDefaultStore } from "../hooks/useIsDefaultStore";
import { useThousandSeparator } from "../hooks/useThousandSeparator";
import {
  AVAILABILITY,
  ITEM_CONDITION,
} from "../libs/richSnippetsUtils/buildProductJSONLd";
import { useTrackEvents } from "../modules/track-events/ui/hooks/use-track-events";
import {
  actualizar_item_carrito,
  add_al_carrito,
  dec_al_carrito,
  inc_al_carrito,
  remove_al_carrito,
  set_peso_al_carrito,
} from "../redux/actions/carrito";
import { getByConfig } from "../redux/actions/config";
import { set_all_prod_category } from "../redux/actions/home";
import {
  get_producto_item,
  limpiar_item_producto,
} from "../redux/actions/producto";
import ButtonClose from "../widgets/button_close";
import ButtonText from "../widgets/button_text";
import Favorito from "../widgets/favoritoMycart";
import LoaderSpinner from "../widgets/loaderSpinner";
import useScreenSize from "../widgets/useScreenSize";
import { MensajeTelefonoProductoDisponible } from "./MensajeTelefonoProductoDisponible";
const customStylesModalSucursal = {
  overlay: {
    backgroundColor: "#000000a6",
    zIndex: "30",
  },
  content: {
    padding: "0px",
    border: "0px",
    background: "transparent",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "calc(100% - 64px)",
    overflow: "hidden",
  },
};

const customStyles = {
  overlay: {
    backgroundColor: "#000000a6",
    zIndex: "1",
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    borderRadius: "0.75rem",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "visible",
  },
};
const customStyles2 = {
  overlay: {
    backgroundColor: "#000000a6",
    zIndex: "100",
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    borderRadius: "0.75rem",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "visible",
  },
};

const pesajes = [
  { name: "500g", peso: "500" },
  { name: "1Kg", peso: "1000" },
  { name: "2Kg", peso: "2000" },
  { name: "3Kg", peso: "3000" },
  { name: "5Kg", peso: "5000" },
  { name: "10Kg", peso: "10000" },
  { name: "15Kg", peso: "15000" },
  { name: "20Kg", peso: "20000" },
  { name: "25Kg", peso: "25000" },
  { name: "30Kg", peso: "30000" },
  { name: "35Kg", peso: "35000" },
  { name: "40Kg", peso: "40000" },
  { name: "45Kg", peso: "45000" },
  { name: "50Kg", peso: "50000" },
];

const style_favorito = {
  overlay: {
    backgroundColor: "#000000a6",
    zIndex: "31",
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: "0",
    borderRadius: "0.75rem",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "auto",
    border: "none",
    background: "transparent",
  },
};
const InformacionProducto = ({
  limpiar_item_producto,
  get_producto_item,
  producto,
  carrito,
  sucursal,
  set_peso_al_carrito,
  add_al_carrito,
  inc_al_carrito,
  dec_al_carrito,
  remove_al_carrito,
  actualizar_item_carrito,
  direccion,
  sucursales,
  profile,
  isLoading: isLoadingProducto,
}) => {
  const { trackAddToCartEvent, trackProductEvent } = useTrackEvents();
  const { isDefaultStore } = useIsDefaultStore();
  const { currentUrl } = useGetCurrentUrl();
  const { numberFormatter } = useThousandSeparator();

  const [thumbsSwiperDesktop, setThumbsSwiperDesktop] = useState(null);
  const [thumbsSwiperMobile, setThumbsSwiperMobile] = useState(null);
  const { productoId } = useParams();
  const { ciudadp, sucursalp } = useParams();
  const [imagenes, guardarImagenes] = useState([]);
  const [pesos, guardarPesos] = useState([]);
  const [cantidad, guardarCantidad] = useState(0);
  const [monto, guardarMonto] = useState(0);
  const [hubo_cambios, setHuboCambios] = useState(false);
  const [modal, setModal] = useState(false);
  const [modal_favorito, setModalFavorito] = useState(false);
  const startModal = () => setModal(true);
  const endModal = () => setModal(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [anchoPantalla, setAnchoPantalla] = useState(window.innerWidth);
  const [url_navigation, setUrl_navigation] = useState(null);
  const [mensajeProductoReceta, setMensajeProductoReceta] = useState("");

  const { width } = useScreenSize();

  const openModalFavorito = () => setModalFavorito(true);
  const closeModalFavorito = () => setModalFavorito(false);

  const [openModalUserRegister, setOpenModalUserRegister] = useState(false);
  const [openModalSucursal, setOpenModalSucursal] = useState(false);

  const isUserAuthenticated = useMemo(() => {
    if (!profile) return null;
    return profile?.Telefono != null && profile?.Telefono !== "";
  }, [profile]);

  const tieneCobertura = useMemo(() => {
    if (!direccion) return true;
    const sucursalEncontrada = sucursales?.find(
      (suc) => suc.IdMarket === sucursal.IdMarket
    );

    const locatarioEncontrado = sucursalEncontrada?.Locatarios.find(
      (locatario) => locatario.IdSucursal === sucursal.IdSucursal
    );
    return !!locatarioEncontrado;
  }, [sucursal, sucursales, direccion]);

  const isLoading = isLoadingProducto || isUserAuthenticated === null;

  const handleOpenModalSucursal = () => {
    setOpenModalSucursal(true);
  };

  const handleCloseModalSucursal = () => {
    setOpenModalSucursal(false);
  };

  const actualizarAnchoPantalla = () => {
    setAnchoPantalla(window.innerWidth);
  };

  const total = (monto * cantidad).toFixed(2);

  const _es_pieza = () => {
    if (producto) {
      return producto.Fraccionado && producto.PesoDefinido ? true : false;
    } else {
      return false;
    }
  };

  const _estimado = () => {
    let result = 0;
    if (_es_pieza()) {
      pesos.forEach((peso) => {
        result += peso.Piezas * peso.PesoAproxMax;
      });
    } else {
      result = cantidad;
    }
    return Number(result).toFixed(2);
  };

  const _ahorrado = () => {
    let resultado = 0;
    if (producto) {
      let precio_venta = producto.ConOferta
        ? producto.PrecioOriginal
        : producto.PrecioVenta;
      if (_es_pieza()) {
        let cantidad_pieza = _estimado();
        let aux = producto.ConOferta
          ? producto.PrecioOferta
          : producto.PrecioVenta;
        let total_sin_oferta = cantidad_pieza * producto.PrecioOriginal;
        let total_oferta = cantidad_pieza * aux;
        resultado = total_sin_oferta - total_oferta;
      } else {
        resultado = precio_venta * cantidad - total;
      }
    }

    return resultado.toFixed(2);
  };
  const ahorrado = _ahorrado();
  const _descuento = () => {
    let resultado = 0;
    if (producto) {
      if (producto.ConOferta) {
        resultado =
          100 - (producto.PrecioOferta * 100) / producto.PrecioOriginal;
      }
    }
    return resultado.toFixed(1);
  };
  const descuento = _descuento();
  const _cantidad_peso = () => {
    let result = cantidad * 1000;
    return result.toFixed(0) + "g";
  };

  const _total_estimado = () => {
    let result = 0;
    result = _es_pieza() ? monto * estimado : monto * cantidad;
    return result.toFixed(2);
  };

  const cargar_url_var = async () => {
    let resp = ciudadp + " " + sucursalp;
    let url_parameters = await convertSlug.data_barra(resp);
    setUrl_navigation(url_parameters);
  };
  const estimado = _estimado();
  const total_estimado = _total_estimado();
  const cantidad_peso = _cantidad_peso();
  useEffect(() => {
    if (producto?.IdProducto) {
      trackProductEvent({
        idProducto: producto.IdProducto,
        precio: producto.ConOferta
          ? producto.PrecioOferta
          : producto.PrecioVenta,
        descripcion: producto.Descripcion,
      });
    }
  }, [
    producto?.IdProducto,
    producto?.ConOferta,
    producto?.PrecioOferta,
    producto?.PrecioVenta,
  ]);

  useEffect(() => {
    setAnchoPantalla(width);
  }, [width]);
  useEffect(() => {
    if (isUserAuthenticated !== null) {
      get_producto_item(sucursal.IdSucursal, productoId, true);
    }
  }, [sucursal.IdSucursal, productoId, isUserAuthenticated]);
  useEffect(() => {
    if (ciudadp != null && sucursalp != null) {
      cargar_url_var();
    }
  }, [ciudadp, sucursalp]);

  useEffect(() => {
    return () => {
      limpiar_item_producto();
      window.addEventListener("resize", actualizarAnchoPantalla);
    };
  }, []);

  useEffect(() => {
    const fetchKeyConfig = async () => {
      try {
        const response = await getByConfig("MensajeProductoConReceta");
        if (response.ConError === true || response?.Dato == null) {
          setMensajeProductoReceta("");
        } else {
          setMensajeProductoReceta(response?.Dato?.Valor || "");
        }
      } catch (error) {
        console.error("Error fetching config:", error);
        setMensajeProductoReceta("");
      }
    };

    fetchKeyConfig();
  }, []);

  useEffect(() => {
    let cantidad = 0;
    pesos.forEach((peso) => {
      cantidad += peso.Piezas;
    });
    guardarCantidad(cantidad);
  }, [pesos]);
  useEffect(() => {
    if (cantidad === 0) {
      if (_es_pieza() && hubo_cambios) {
        remove_al_carrito(producto);
      }
    } else {
      if (_es_pieza() && hubo_cambios) {
        let pesos_aux = [];
        pesos.forEach((elem) => {
          if (elem.Piezas > 0) {
            pesos_aux.push(elem);
          }
        });
        set_peso_al_carrito(producto, cantidad, pesos_aux);
      }
    }
  }, [cantidad]);

  useEffect(() => {
    if (producto) {
      if (_es_pieza()) {
        _peso_definido();
      }
      let producto_carrito = carrito.find(
        (prd) => prd.IdProducto === producto.IdProducto
      );
      let monto_aux = producto.ConOferta
        ? producto.PrecioOferta
        : producto.PrecioVenta;
      guardarMonto(monto_aux);
      // if (producto_carrito) {
      guardarCantidad(producto_carrito?.Cantidad || 0);
      // }

      let fotos = [];
      if (producto.Fotos) {
        fotos = producto.Fotos;
      } else {
        let object = {
          orden: 1,
          Url: producto.UrlFoto,
        };
        fotos.push(object);
      }

      guardarImagenes(fotos);
    }
  }, [producto, carrito]);

  const add_carrito = async () => {
    if (!isUserAuthenticated) {
      abrir_modal_registro();
      return;
    }
    if (!tieneCobertura) {
      handleOpenModalSucursal();
      return;
    }
    let pesaje = 1;
    if (_es_peso()) {
      pesaje = 0.1;
    }
    await guardarCantidad(Number(cantidad) + pesaje);
    if (cantidad === 0) {
      add_al_carrito(producto, pesaje);
    } else {
      inc_al_carrito(producto);
    }
  };

  const _peso_definido = () => {
    const producto_carrito = carrito.find(
      (prd) => prd.IdProducto === producto.IdProducto
    );
    var protudctoCopia = { ...producto_carrito };

    let pesos_aux = set_piezas_carrito(protudctoCopia.Pesos);

    if (producto_carrito) {
      guardarCantidad(protudctoCopia.Cantidad);
    }
    guardarPesos(pesos_aux);
  };

  const actualizarDato = (peso, operacion) => {
    if (!isUserAuthenticated) {
      abrir_modal_registro();
      return;
    }
    if (!tieneCobertura) {
      handleOpenModalSucursal();
      return;
    }
    setHuboCambios(true);
    if (operacion === "incremento") {
      var pesoCalculado = pesos.map((ps) => {
        if (ps.IdPeso === peso.IdPeso) {
          return {
            ...ps,
            Piezas: Number(ps.Piezas) + 1,
          };
        } else {
          return ps;
        }
      });
      guardarPesos(pesoCalculado);
    } else if (operacion === "decremento") {
      guardarPesos(
        pesos.map((ps) => {
          if (ps.IdPeso === peso.IdPeso && ps.Piezas >= 1) {
            return {
              ...ps,
              Piezas: Number(ps.Piezas) - 1,
            };
          } else {
            return ps;
          }
        })
      );
    }
  };

  const set_piezas_carrito = (pesos) => {
    producto.Pesos.forEach((peso) => {
      let peso_aux = null;
      if (pesos) {
        peso_aux = pesos.find((ps) => ps.IdPeso === peso.IdPeso);
      }
      if (peso_aux) {
        peso.Piezas = peso_aux.Piezas;
      } else {
        peso.Piezas = 0;
      }
    });
    return producto.Pesos;
  };

  const inputchangePeso = (e, peso) => {
    if (!isUserAuthenticated) {
      abrir_modal_registro();
      return;
    }
    if (!tieneCobertura) {
      handleOpenModalSucursal();
      return;
    }
    const esValido = e.target.validity.valid;
    if (esValido) {
      let peso_value = e.target.value;
      if (peso_value) {
        guardarPesos(
          pesos.map((ps) => {
            if (ps.IdPeso === peso.IdPeso) {
              setHuboCambios(true);
              return {
                ...ps,
                Piezas: Number(e.target.value),
              };
            } else {
              return ps;
            }
          })
        );
      }
    }
  };

  //finaliza array peso su lógica
  //cuando es por pieza la logica
  function _es_peso() {
    if (producto) {
      return producto.Fraccionado && !producto.PesoDefinido ? true : false;
    } else {
      return false;
    }
  }

  function abrirModal() {
    startModal(true);
  }

  function actualizar_item(valor, operacion) {
    if (!isUserAuthenticated) {
      abrir_modal_registro();
      return;
    }
    if (!tieneCobertura) {
      handleOpenModalSucursal();
      return;
    }
    let resultado = 0;
    resultado =
      operacion == "incremento"
        ? Number((cantidad + valor).toFixed(1))
        : Number((cantidad - valor).toFixed(1));
    if (resultado < 0) {
      return;
    }
    if (operacion == "incremento" && cantidad == 0) {
      guardarCantidad(resultado);
      add_al_carrito(producto, resultado);
    } else if (operacion == "decremento" && cantidad == valor) {
      guardarCantidad(resultado);
      remove_al_carrito(producto);
    } else {
      guardarCantidad(resultado);
      actualizar_item_carrito(producto, resultado);
    }
  }

  function set_peso_pieza(peso) {
    let resultado = peso.peso / 1000;
    guardarCantidad(resultado);
    actualizar_item_carrito(producto, resultado);
  }

  const reducir_carrito = () => {
    if (!isUserAuthenticated) {
      abrir_modal_registro();
      return;
    }
    if (!tieneCobertura) {
      handleOpenModalSucursal();
      return;
    }
    if (cantidad === 1) {
      guardarCantidad(cantidad - 1);
      remove_al_carrito(producto);
    } else if (cantidad >= 1) {
      guardarCantidad(cantidad - 1);
      dec_al_carrito(producto);
    }
  };

  const inputchange = (e) => {
    if (!isUserAuthenticated) {
      abrir_modal_registro();
      return;
    }
    if (!tieneCobertura) {
      handleOpenModalSucursal();
      return;
    }
    const esValido = e.target.validity.valid;
    if (esValido) {
      let cant = e.target.value;
      if (cant) {
        if (producto.Fraccionado & !producto.PesoDefinido) {
        } else {
          guardarCantidad(cant);
          if (cant > 0) {
            add_al_carrito(producto, Number(cant));
          } else {
            remove_al_carrito(producto);
          }
        }
      }
    }
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const show_subcategoria = (cat_id, subcat_id) => {
    navigate("/" + url_navigation + `/categoria/${cat_id}/${subcat_id}/`);
  };

  const show_categoria = (cat_id) => {
    navigate("/" + url_navigation + `/categoria/${cat_id}`);
  };

  const formatNumber = (value) => {
    return numberFormatter.format(value);
  };

  const fueraFoco = () => {};

  const show_botones = () => {
    if (!isUserAuthenticated) {
      abrir_modal_registro();
      return;
    }
    if (!tieneCobertura) {
      handleOpenModalSucursal();
      return;
    }
    add_carrito();
    let cantidad = 1;
    if (_es_peso()) {
      cantidad = 0.1;
      //  actualizarDato(peso, "incremento");
    }
    trackAddToCartEvent({
      idProducto: producto.IdProducto,
      cantidad: cantidad,
      descripcion: producto.Descripcion,
      subtotal:
        (producto.ConOferta ? producto.PrecioOferta : producto.PrecioVenta) *
        cantidad,
      precio: producto.ConOferta ? producto.PrecioOferta : producto.PrecioVenta,
    });
  };

  const verifyUserAutenticate = () => {
    if (profile.Telefono !== "") {
      return true;
    }
    return false;
  };

  const show_ventana_favorito = () => {
    if (isUserAuthenticated) {
      openModalFavorito();
    } else {
      abrir_modal_registro();
    }
  };

  const abrir_modal_registro = () => {
    setOpenModalUserRegister(true);
  };
  const cerrarVentana = (valor) => {
    setOpenModalUserRegister(false);
  };

  useEffect(() => {
    if (thumbsSwiperDesktop?.destroyed) {
      setThumbsSwiperDesktop(null);
    }

    if (thumbsSwiperMobile?.destroyed) {
      setThumbsSwiperMobile(null);
    }

    return () => {
      setThumbsSwiperMobile(null);
      setThumbsSwiperDesktop(null);
    };
  }, [thumbsSwiperDesktop?.destroyed, thumbsSwiperMobile?.destroyed]);

  const { breadcrumbs: itemListElement } = useBreadcrumbs({
    categoria: producto?.Categoria.Descripcion || null,
    subcategoria: producto?.SubCategoria.Descripcion || null,
    producto: producto?.Descripcion || null,
  });
  const { breadcrumbListJSONLdScript } = useBreadcrumbListJSONLd({
    itemListElement,
  });

  const { productJSONLdScript } = useProductJSONLd({
    name: producto?.Descripcion,
    image:
      producto?.Fotos?.length > 1
        ? producto?.Fotos.map((foto) => `${foto.Url}&size=400x400`)
        : `${producto?.UrlFoto}&size=400x400`,
    url: currentUrl,
    sku: producto?.IdProducto,
    offers: {
      priceCurrency: "BOB",
      price: producto?.PrecioVenta,
      itemCondition: ITEM_CONDITION.NEW,
      availability: AVAILABILITY.IN_STOCK,
      seller: {
        name: sucursal.Descripcion,
      },
    },
  });

  const { sucursalCiudad } = useGetSucursalCiudad({
    idRegion: sucursal.idRegion,
  });

  const keywords = [
    `${process.env.REACT_APP_ORGANIZATION_NAME} ${
      sucursalCiudad?.Nombre || ""
    }`,
    sucursal?.Descripcion || "",
    `${sucursal?.TipoServicio.Descripcion || ""} ${
      sucursalCiudad?.Nombre || ""
    }`,
    `${sucursal?.TipoServicio.Descripcion || ""} online Bolivia`,
    `Tienda online ${sucursal?.Descripcion}`,
    `Compras online ${sucursalCiudad?.Nombre || ""}`,
    `Entrega a domicilio ${sucursalCiudad?.Nombre || ""}`,
    `Compras en línea ${sucursalCiudad?.Nombre || ""}`,
    `Ofertas ${sucursal?.Descripcion}`,
    `Descuentos ${sucursal?.Descripcion}`,
    `Marcas exclusivas ${sucursal?.Descripcion}`,
    `Promociones ${sucursal?.Descripcion}`,
    `Ofertas especiales ${sucursalCiudad?.Nombre || ""}`,
    `Delivery de ${sucursal?.TipoServicio.Descripcion || ""} Bolivia`,
    `Delivery de ${sucursal?.TipoServicio.Descripcion || ""} ${
      sucursalCiudad?.Nombre
    }`,
    `Delivery de ${sucursal?.TipoServicio.Descripcion || ""} ${
      sucursal?.Descripcion
    }`,
    DEFAULT_KEYWORDS,
  ];

  if (!!producto) {
    keywords.push(producto.Descripcion);
    keywords.push(`Compra de ${producto.Descripcion}`);
    keywords.push(`${producto.Descripcion} Bolivia`);
    keywords.push(`Compra de ${producto.Descripcion} en Bolivia`);
    keywords.push(`${producto.Descripcion} ${sucursal?.Descripcion || ""}`);
    keywords.push(
      `Compra de ${producto.Descripcion} en ${sucursal?.Descripcion || ""}`
    );
  }

  if (!!producto?.Categoria) {
    keywords.push(producto?.Categoria.Descripcion);
    keywords.push(`Compra de ${producto?.Categoria.Descripcion}`);

    keywords.push(`${producto?.Categoria.Descripcion} Bolivia`);
    keywords.push(`Compra de ${producto?.Categoria.Descripcion} en Bolivia`);

    keywords.push(
      `${producto?.Categoria.Descripcion} ${sucursal?.Descripcion || ""}`
    );
    keywords.push(
      `Compra de ${producto?.Categoria.Descripcion} en ${
        sucursal?.Descripcion || ""
      }`
    );
  }

  if (!!producto?.SubCategoria) {
    keywords.push(producto?.SubCategoria.Descripcion);
    keywords.push(`Compra de ${producto?.SubCategoria.Descripcion}`);
    keywords.push(`${producto?.SubCategoria.Descripcion} Bolivia`);
    keywords.push(`Compra de ${producto?.SubCategoria.Descripcion} en Bolivia`);
    keywords.push(
      `${producto?.SubCategoria.Descripcion} ${sucursal?.Descripcion || ""}`
    );
    keywords.push(
      `Compra de ${producto?.SubCategoria.Descripcion} en ${
        sucursal?.Descripcion || ""
      }`
    );
  }

  const displaySEO = !!producto && !!sucursal && !!sucursalCiudad;

  return (
    <>
      {displaySEO && (
        <ProductSEO
          title={`${producto.Descripcion || "Producto no disponible"} | ${
            isDefaultStore ? "Hipermaxi" : sucursal?.Descripcion || ""
          }`}
          store={`${sucursal.Descripcion}`}
          description={`Realiza tu compra en línea de ${
            producto.Descripcion || "productos"
          } en ${
            !sucursal || isDefaultStore
              ? "tu sucursal Hipermaxi más cercana"
              : `la sucursal ${sucursal.Descripcion}`
          } en Bolivia. Mediante unos simples pasos, envía el producto al carrito de compra, decide cómo pagar y recíbelo en la puerta de tu casa. Disfruta de la conveniencia de ${
            !sucursal || isDefaultStore
              ? "nuestra sucursal"
              : `${
                  KEYWORD_BY_SERVICE_TYPE[
                    sucursal.TipoServicio.Descripcion.toUpperCase()
                  ]
                }`
          } en línea Hipermaxi y encuentra una amplia variedad de productos locales, productos importados y ofertas exclusivas.`}
          breadcrumbListJSONLdScript={breadcrumbListJSONLdScript}
          productJSONLdScript={productJSONLdScript}
          image={`${producto.UrlFoto}&size=400x400`}
          url={currentUrl}
          productPrice={formatNumber(
            producto?.PrecioOferta > 0
              ? producto?.PrecioOriginal
              : producto?.PrecioVenta
          )}
          currency="BOB"
          availability="in stock"
          condition="new"
          retailerItemId={producto?.IdProducto}
          productDiscount={
            producto?.PrecioOferta > 0
              ? formatNumber(producto?.PrecioOferta)
              : ""
          }
          keywords={keywords}
        />
      )}

      <ZoomProvider>
        <Layout>
          <Alert></Alert>
          {openModalUserRegister && (
            <RegistroInit setCloseModal={cerrarVentana}></RegistroInit>
          )}
          <div className="relative flex-grow md:mt-4">
            <div className="mx-auto max-w-[1920px] container_main">
              <div className="flex pb-4 pt-1">
                <div className="w-full container_prod">
                  {isLoading ? (
                    <div className="flex justify-center text-black text-center pb-2">
                      <LoaderSpinner bool={true} />
                    </div>
                  ) : (
                    <>
                      {producto ? (
                        <div className="container">
                          <div className="header-responsive-categorias   ">
                            <div>
                              <div className="flex justify-start">
                                {producto.Categoria?.Descripcion !== "" ? (
                                  <Link
                                    className="md:px-2 md:py-2.5 bg-gray-400 font-bold text-white rounded mr-1 text-sm md:text-base leading-none flex items-center"
                                    style={{
                                      padding: "8px 6px",
                                    }}
                                    to={
                                      "/" +
                                      url_navigation +
                                      `/categoria/${convertSlug.data_slug(
                                        producto.Categoria.Descripcion
                                      )}`
                                    }
                                  >
                                    {producto.Categoria.Descripcion}
                                  </Link>
                                ) : null}
                                {producto.Categoria?.Descripcion !== "" ? (
                                  <Link
                                    className="md:px-2 md:py-2.5 bg-green-500 font-bold text-white rounded mr-1 text-sm md:text-base leading-none flex items-center"
                                    style={{
                                      padding: "8px 6px",
                                    }}
                                    to={
                                      "/" +
                                      url_navigation +
                                      `/categoria/${convertSlug.data_slug(
                                        producto.Categoria.Descripcion
                                      )}/${convertSlug.data_slug(
                                        producto.SubCategoria.Descripcion
                                      )}/`
                                    }
                                  >
                                    {producto.SubCategoria.Descripcion}
                                  </Link>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="product-detail-images w-full h-full grid md:grid-cols-2 ga-2 md:gap-4">
                            <div className=" hidden md:flex md:gap-2 relative">
                              <button
                                className="absolute right-2 top-2 z-20 flex items-center justify-center bg-white w-12 h-12 rounded-full shadow-black hover:shadow-md group transition duration-200 ease-in-out transform hover:scale-110 hover:border"
                                onClick={() => show_ventana_favorito()}
                              >
                                <FontAwesomeIcon
                                  size="2x"
                                  icon={faHeart}
                                  className={classNames("", {
                                    "text-hiperorange":
                                      producto.EnListadoCliente,
                                    "hipergrisdark text-hipergrisdark ":
                                      !producto.EnListadoCliente,
                                  })}
                                />
                              </button>
                              <div className="flex flex-col w-[15%] h-full">
                                <button
                                  style={{
                                    display:
                                      producto?.Fotos?.length > 1
                                        ? "block"
                                        : "none",
                                  }}
                                  className={`absolute z-10 top-0 bottom-0 p-2 md:static text-gray-500 bg-transparent hover:text-gray-800 hover:bg-black hover:bg-opacity-5 disabled:bg-transparent disabled:text-gray-300 review-swiper-button-prev-${producto.IdProducto} rounded-t-lg`}
                                >
                                  <FontAwesomeIcon icon={faAngleUp} size="xl" />
                                </button>
                                <Swiper
                                  id={`carousel-${producto.IdProducto}-desktop-images`}
                                  direction="vertical"
                                  onSwiper={setThumbsSwiperDesktop}
                                  spaceBetween={5}
                                  slidesPerView={4}
                                  freeMode={true}
                                  watchSlidesProgress={true}
                                  modules={[FreeMode, Navigation, Thumbs]}
                                  className="mySwiper h-full"
                                >
                                  {imagenes.map((foto, index) => (
                                    <SwiperSlide
                                      key={"ima" + index}
                                      className="w-full h-fit"
                                    >
                                      <div className="w-full h-fit relative">
                                        <LazyLoadImage
                                          src={
                                            foto.Url +
                                            (anchoPantalla >= 770
                                              ? `&size=400x400`
                                              : "&size=120x120")
                                          }
                                          alt={`${
                                            producto.Descripcion
                                          } Miniatura ${index + 1}`}
                                          className="cursor-pointer"
                                          placeholder={
                                            <div className="absolute animate-pulse inset-0 w-full h-12 backdrop-blur-lg bg-slate-200/40 text-slate-200/40 rounded-lg" />
                                          }
                                        />
                                      </div>
                                    </SwiperSlide>
                                  ))}
                                </Swiper>
                                <button
                                  style={{
                                    display:
                                      producto?.Fotos?.length > 1
                                        ? "block"
                                        : "none",
                                  }}
                                  className={`absolute z-10 top-0 bottom-0 right-0 md:static p-2 text-gray-500 bg-transparent hover:text-gray-800 hover:bg-black hover:bg-opacity-5 disabled:bg-transparent disabled:text-gray-300 review-swiper-button-next-${producto.IdProducto} rounded-b-lg`}
                                >
                                  <FontAwesomeIcon
                                    icon={faAngleDown}
                                    size="xl"
                                  />
                                </button>
                              </div>
                              <div className="flex w-[85%]">
                                <Swiper
                                  id={`carousel-${producto.IdProducto}-desktop-thumbs`}
                                  spaceBetween={10}
                                  navigation={{
                                    nextEl: `.review-swiper-button-next-${producto.IdProducto}`,
                                    prevEl: `.review-swiper-button-prev-${producto.IdProducto}`,
                                  }}
                                  pagination={{
                                    type: "fraction",
                                  }}
                                  thumbs={{
                                    swiper:
                                      thumbsSwiperDesktop &&
                                      !thumbsSwiperDesktop?.destroyed
                                        ? thumbsSwiperDesktop
                                        : null,
                                  }}
                                  modules={[Thumbs, Pagination]}
                                  className="mySwiper2"
                                >
                                  {imagenes.map((foto, index) => (
                                    <SwiperSlide
                                      key={"ima" + index}
                                      className="px-12"
                                    >
                                      <ImageZoom
                                        containerClassName="w-fit h-fit md:min-h-[193px] lg:min-h-[372px] xl:min-h-[448px] flex items-center justify-center"
                                        imageSrc={foto.Url + `&size=900x900`}
                                        highResImageSrc={
                                          foto.Url + `&size=900x900`
                                        }
                                        zoomFactor={2}
                                        alt={`${producto.Descripcion} Imagen ${
                                          index + 1
                                        }`}
                                        width={900}
                                        height={900}
                                        placeholder={
                                          <div className="absolute animate-pulse inset-0 w-full h-96 backdrop-blur-lg bg-slate-200/40 text-slate-200/40 rounded-lg" />
                                        }
                                      />
                                    </SwiperSlide>
                                  ))}
                                </Swiper>
                              </div>
                            </div>

                            <div className=" flex flex-col gap-1 md:hidden w-full items-center relative">
                              {producto.ConOferta && (
                                <div className="block md:hidden absolute top-6 left-1 z-10 -rotate-45">
                                  <div className="py-1 px-1.5 text-xs bg-hiperorange text-white inline-flex items-center gap-1 relative h-[22px] rounded-r-sm">
                                    <span className="font-semibold mr-1">
                                      -{descuento} %
                                    </span>
                                    <div className="absolute -right-[8px] rotate-45 h-4 w-4 flex items-center justify-center bg-hiperorange rounded-sm">
                                      <div className="w-1.5 h-1.5 bg-white rounded-full"></div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              <button
                                className="absolute right-2 top-2 z-20 flex items-center justify-center bg-white w-12 h-12 rounded-full shadow-black hover:shadow-md group transition duration-200 ease-in-out transform hover:scale-110 hover:border"
                                onClick={() => show_ventana_favorito()}
                              >
                                <FontAwesomeIcon
                                  size="2x"
                                  icon={faHeart}
                                  className={classNames("", {
                                    "text-hiperorange":
                                      producto.EnListadoCliente,
                                    "hipergrisdark text-hipergrisdark ":
                                      !producto.EnListadoCliente,
                                  })}
                                />
                              </button>
                              <div className="flex w-full relative h-[80%] gap-1 md:max-w-[22rem] mt-3 md:mt-4">
                                <button
                                  style={{
                                    display:
                                      producto?.Fotos?.length > 1
                                        ? "block"
                                        : "none",
                                  }}
                                  className={`absolute z-10 top-0 bottom-0 p-2 md:static text-gray-500 bg-transparent hover:text-gray-800 hover:bg-black hover:bg-opacity-5 disabled:bg-transparent disabled:text-gray-300 review-swiper-button-prev-${producto.IdProducto} rounded-l-lg`}
                                >
                                  <FontAwesomeIcon
                                    icon={faAngleLeft}
                                    size="xl"
                                  />
                                </button>
                                <Swiper
                                  id={`carousel-${producto.IdProducto}-mobile-images`}
                                  style={{
                                    "--swiper-navigation-color": "#000000",
                                    "--swiper-pagination-color": "#000000",
                                  }}
                                  spaceBetween={10}
                                  navigation={{
                                    nextEl: `.review-swiper-button-next-${producto.IdProducto}`,
                                    prevEl: `.review-swiper-button-prev-${producto.IdProducto}`,
                                  }}
                                  // pagination={{
                                  //   type: "fraction",
                                  // }}
                                  thumbs={{
                                    swiper:
                                      thumbsSwiperMobile &&
                                      !thumbsSwiperMobile?.destroyed
                                        ? thumbsSwiperMobile
                                        : null,
                                  }}
                                  modules={[
                                    // FreeMode,
                                    // Navigation,
                                    Thumbs,
                                    // Pagination,
                                  ]}
                                  className="mySwiper4 max-w-[calc(100vw-128px)]"
                                >
                                  {/*responsive*/}
                                  {imagenes.map((foto, index) => (
                                    <SwiperSlide key={"ima" + index}>
                                      <div className="w-fit h-fit relative">
                                        <LazyLoadImage
                                          src={foto.Url + `&size=400x400`}
                                          width={400}
                                          height={400}
                                          alt={`${
                                            producto.Descripcion
                                          } Imagen ${index + 1}`}
                                          placeholder={
                                            <div className="absolute animate-pulse inset-0 w-full h-60 backdrop-blur-lg bg-slate-200/40 text-slate-200/40 rounded-lg" />
                                          }
                                        />
                                      </div>
                                    </SwiperSlide>
                                  ))}
                                </Swiper>
                                <button
                                  style={{
                                    display:
                                      producto?.Fotos?.length > 1
                                        ? "block"
                                        : "none",
                                  }}
                                  className={`absolute z-10 top-0 bottom-0 right-0 md:static p-2 text-gray-500 bg-transparent hover:text-gray-800 hover:bg-black hover:bg-opacity-5 disabled:bg-transparent disabled:text-gray-300 review-swiper-button-next-${producto.IdProducto} rounded-r-lg`}
                                >
                                  <FontAwesomeIcon
                                    icon={faAngleRight}
                                    size="xl"
                                  />
                                </button>
                              </div>
                              <div className="flex w-full relative">
                                <Swiper
                                  id={`carousel-${producto.IdProducto}-mobile-thumbs`}
                                  onSwiper={setThumbsSwiperMobile}
                                  spaceBetween={10}
                                  slidesPerView={6}
                                  freeMode={true}
                                  watchSlidesProgress={true}
                                  modules={[FreeMode, Navigation, Thumbs]}
                                  className="mySwiper3"
                                >
                                  {/*laterales web*/}

                                  {imagenes.map((foto, index) => (
                                    <SwiperSlide key={"ima" + index}>
                                      <div className="w-full h-fit relative">
                                        <LazyLoadImage
                                          src={foto.Url + `&size=400x400`}
                                          alt={`${
                                            producto.Descripcion
                                          } Miniatura ${index + 1}`}
                                          className="cursor-pointer"
                                          placeholder={
                                            <div className="absolute animate-pulse inset-0 w-full h-10 backdrop-blur-lg bg-slate-200/40 text-slate-200/40 rounded-lg" />
                                          }
                                        />
                                      </div>
                                    </SwiperSlide>
                                  ))}
                                </Swiper>
                              </div>
                            </div>

                            <div className="z-10 md:min-h-[300px] lg:min-h-[400px] xl:min-h-[500px] relative">
                              <ZoomedImage />
                              <div className="style-categorias  ">
                                <div className="flex justify-start">
                                  {producto.Categoria?.Descripcion !== "" ? (
                                    <div
                                      className="bg-gray-400   font-bold text-white rounded mr-1  flex align-items-center "
                                      style={{
                                        cursor: "pointer",
                                        padding: "10px 8px 10px 8px",
                                      }}
                                      onClick={() =>
                                        show_categoria(
                                          convertSlug.data_slug(
                                            producto.Categoria.Descripcion
                                          )
                                        )
                                      }
                                    >
                                      {producto.Categoria.Descripcion}
                                    </div>
                                  ) : null}
                                  {producto.SubCategoria?.Descripcion !== "" ? (
                                    <div
                                      className="bg-gray-500  flex align-items-center font-bold  text-white rounded mx-1"
                                      style={{
                                        cursor: "pointer",
                                        padding: "10px 8px 10px 8px",
                                      }}
                                      onClick={() =>
                                        show_subcategoria(
                                          convertSlug.data_slug(
                                            producto.Categoria.Descripcion
                                          ),
                                          convertSlug.data_slug(
                                            producto.SubCategoria.Descripcion
                                          )
                                        )
                                      }
                                    >
                                      {producto.SubCategoria.Descripcion}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="flex flex-col gap-2">
                                <div className="font-bold text-lg leading-none md:leading-7 md:text-[24px] md:my-1.5 md:h-auto">
                                  {producto.Descripcion}
                                </div>
                                {producto.ConOferta && (
                                  <div className="hidden md:block">
                                    <div className="py-2 px-3 text-lg bg-hiperorange text-white rounded-[20px] inline-flex items-center gap-2">
                                      <FontAwesomeIcon
                                        icon={faTag}
                                        className="w-[1.5rem] h-[1.5rem]"
                                      />
                                      <span className="font-semibold">
                                        -{descuento}% Dcto.
                                      </span>
                                    </div>
                                  </div>
                                )}

                                <div className="w-full flex flex-col gap-1">
                                  {producto.ConOferta && cantidad > 0 && (
                                    <div className="self-end block md:hidden">
                                      <div className="py-1 px-2 text-sm bg-hiperorange text-white rounded-[20px] inline-flex items-center gap-2">
                                        Bs. {formatNumber(ahorrado)}&nbsp;
                                        Ahorrado
                                      </div>
                                    </div>
                                  )}
                                  <div className="grid grid-cols-2 md:grid-cols-1 gap-1">
                                    <div className="flex flex-col">
                                      <div className="font-bold h-auto text-sm md:text-base">
                                        {_es_peso() ? (
                                          <span>Precio por Kg </span>
                                        ) : (
                                          <></>
                                        )}
                                        {_es_pieza() ? (
                                          <span>Precio por Kg </span>
                                        ) : (
                                          <></>
                                        )}
                                        {!_es_pieza() && !_es_peso() ? (
                                          <span>Precio por Und </span>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                      <div className="text-base md:text-3xl font-bold leading-none">
                                        {producto.ConOferta ? (
                                          <div className="mb-0">
                                            {producto.Moneda}
                                            {". "}
                                            {formatNumber(
                                              producto.PrecioOferta
                                            )}
                                            <del
                                              className="text-zinc-400 px-2"
                                              style={{
                                                fontSize: "24px !important",
                                              }}
                                            >
                                              {producto.Moneda}
                                              {". "}
                                              {formatNumber(
                                                producto.PrecioOriginal
                                              )}
                                            </del>
                                          </div>
                                        ) : (
                                          <div className="mb-0 pl-0">
                                            {producto.Moneda}
                                            {". "}
                                            {formatNumber(producto.PrecioVenta)}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    {cantidad > 0 && (
                                      <div className="flex flex-col text-right md:hidden">
                                        {/* <div>
                                          <p className="mb-0 font-bold">
                                            {_es_peso() ? "Peso" : ""}
                                            {_es_pieza() ? "Peso estimado" : ""}
                                          </p>
                                          {(_es_peso() || _es_pieza()) && (
                                            <p className="mb-0 font-bold">
                                              {formatNumber(estimado)} KG
                                            </p>
                                          )}
                                        </div> */}
                                        <div className="font-bold h-auto text-sm md:text-base">
                                          {!_es_pieza() && <span>Total</span>}
                                          {_es_pieza() && (
                                            <span>Total estimado</span>
                                          )}
                                        </div>
                                        <div className="text-base md:text-3xl font-bold leading-none">
                                          {(_es_peso() || _es_pieza()) && (
                                            <span className="mr-1 text-sm">
                                              ({formatNumber(estimado)} KG)
                                            </span>
                                          )}
                                          {producto.Moneda}
                                          {". "}
                                          {formatNumber(total_estimado)}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div
                                  className={classNames(
                                    "controles-add-minus md:pb-2",
                                    {
                                      hidden: !_es_pieza() && cantidad === 0,
                                      "md:border-b-[1px] md:border-[#ABABAD]":
                                        cantidad > 0,
                                    }
                                  )}
                                >
                                  {!producto.Fraccionado &&
                                    cantidad > 0 /* esnoremal*/ && (
                                      <div className="flex align-items-end controles_info_prod">
                                        <div className="flex w-full">
                                          <button
                                            className="w-10 h-10 bg-hiperorange hover:bg-hiperorange_hover text-white rounded flex items-center justify-center"
                                            onClick={() => reducir_carrito()}
                                          >
                                            <FontAwesomeIcon
                                              size="lg"
                                              icon={
                                                cantidad > 1
                                                  ? faMinus
                                                  : faTrashCan
                                              }
                                            />
                                          </button>
                                          <div className="mx-1 flex justify-center flex-grow md:flex-grow-0 md:w-[100px]">
                                            <input
                                              type="text"
                                              className="w-full h-10 text-center outline-none bg-hipergris rounded-lg px-2 font-bold"
                                              name="cantidad"
                                              pattern="[0-9]{0,13}"
                                              value={cantidad + " Und"}
                                              onChange={(e) => inputchange(e)}
                                            />
                                          </div>
                                          <button
                                            className="w-10 h-10 bg-hiperorange hover:bg-hiperorange_hover text-white rounded flex items-center justify-center"
                                            onClick={() => add_carrito()}
                                          >
                                            <FontAwesomeIcon
                                              size="lg"
                                              icon={faPlus}
                                            />
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  {_es_peso() && cantidad > 0 ? (
                                    <div className="flex align-items-end controles_info_prod">
                                      <div className="flex w-full">
                                        <button
                                          className="w-10 h-10 bg-hiperorange hover:bg-hiperorange_hover text-white rounded flex items-center justify-center"
                                          onClick={() =>
                                            actualizar_item(0.1, "decremento")
                                          }
                                        >
                                          <FontAwesomeIcon
                                            size="lg"
                                            icon={
                                              cantidad > 0.1
                                                ? faMinus
                                                : faTrashCan
                                            }
                                          />
                                        </button>
                                        <button
                                          className="mx-1 flex justify-center align-items-center bg-white cursor-pointer flex-grow md:flex-grow-0 md:w-[100px]"
                                          onClick={() => abrirModal()}
                                        >
                                          <div className="w-full h-10 text-center outline-none bg-hipergris rounded-lg px-2 font-bold flex items-center justify-center">
                                            {cantidad_peso}
                                          </div>
                                        </button>
                                        <button
                                          className="w-10 h-10 bg-hiperorange hover:bg-hiperorange_hover text-white rounded flex items-center justify-center"
                                          onClick={() =>
                                            actualizar_item(0.1, "incremento")
                                          }
                                        >
                                          <FontAwesomeIcon
                                            size="lg"
                                            icon={faPlus}
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}

                                  {_es_pieza() /* espieza*/ ? (
                                    <div className="flex flex-col">
                                      <div className="text-sm md:text-base font-bold">
                                        Peso de unidades disponibles
                                      </div>
                                      {pesos.map((peso, index) => (
                                        <div
                                          className="flex gap-2 md:gap-4 lg:gap-8 my-1 md:mt-2 w-full bg-white md:mb-2 flex-wrap flex-row md:items-center"
                                          key={index}
                                        >
                                          <div className="bg-white h-10 rounded-l flex items-center flex-grow md:flex-grow-0">
                                            <span className="font-bold">
                                              Unidad {peso.PesoAproxMin} -{" "}
                                              {peso.PesoAproxMax} kg
                                            </span>
                                          </div>
                                          <div className="flex w-full max-w-[196px]">
                                            <div className="flex gap-2 items-center">
                                              {peso.Piezas >= 1 && (
                                                <button
                                                  className="w-10 h-10 bg-hiperorange hover:bg-hiperorange_hover text-white rounded flex items-center justify-center"
                                                  onClick={() =>
                                                    actualizarDato(
                                                      peso,
                                                      "decremento"
                                                    )
                                                  }
                                                >
                                                  <FontAwesomeIcon
                                                    size="lg"
                                                    icon={
                                                      peso.Piezas > 1
                                                        ? faMinus
                                                        : faTrashCan
                                                    }
                                                  />
                                                </button>
                                              )}

                                              {peso.Piezas > 0 && (
                                                <input
                                                  type="text"
                                                  className="  h-10 text-center outline-none bg-hipergris rounded-lg px-3 py-1 font-bold "
                                                  name="cantidad"
                                                  pattern="[0-9]{0,13}"
                                                  value={peso.Piezas + " Und"}
                                                  onChange={(e) =>
                                                    inputchangePeso(e, peso)
                                                  }
                                                  style={{
                                                    width: "auto",
                                                    maxWidth: "100px",
                                                  }}
                                                />
                                              )}

                                              {peso.Piezas > 0 && (
                                                <button
                                                  className="w-10 h-10 bg-hiperorange hover:bg-hiperorange_hover text-white rounded flex items-center justify-center"
                                                  onClick={() =>
                                                    actualizarDato(
                                                      peso,
                                                      "incremento"
                                                    )
                                                  }
                                                >
                                                  <FontAwesomeIcon
                                                    size="lg"
                                                    icon={faPlus}
                                                  />
                                                </button>
                                              )}
                                            </div>

                                            {peso.Piezas == 0 && (
                                              <div className="w-full ">
                                                <ButtonText
                                                  type="submit"
                                                  label="Agregar"
                                                  principal={true}
                                                  className="bg-hiperorange  text-white mb-0 hover:bg-hiperorange_hover  w-full"
                                                  onClick={() => {
                                                    actualizarDato(
                                                      peso,
                                                      "incremento"
                                                    );
                                                    trackAddToCartEvent({
                                                      cantidad:
                                                        peso.PesoAproxMax,
                                                      descripcion:
                                                        producto.Descripcion,
                                                      idProducto:
                                                        producto.IdProducto,
                                                      subtotal:
                                                        (producto.ConOferta
                                                          ? producto.PrecioOferta
                                                          : producto.PrecioVenta) *
                                                        peso.PesoAproxMax,
                                                      precio: producto.ConOferta
                                                        ? producto.PrecioOferta
                                                        : producto.PrecioVenta,
                                                    });
                                                  }}
                                                />
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                {(cantidad == 0 || cantidad_peso == "0g") &&
                                  _es_pieza() === false && (
                                    <div className="flex align-items-end controles_info_prod">
                                      <ButtonText
                                        type="submit"
                                        label="Agregar"
                                        principal={true}
                                        className="bg-hiperorange  text-white hover:bg-hiperorange_hover w-full md:w-auto min-w-32"
                                        onClick={() => show_botones()}
                                      />
                                    </div>
                                  )}

                                {cantidad > 0 && (
                                  <div className="block-total hidden md:block">
                                    <div>
                                      <p className="mb-0 font-bold">
                                        {_es_peso() ? "Peso" : ""}
                                        {_es_pieza() ? "Peso estimado" : ""}
                                      </p>
                                      {(_es_peso() || _es_pieza()) && (
                                        <p className="mb-0 font-bold">
                                          {formatNumber(estimado)} KG
                                        </p>
                                      )}
                                    </div>
                                    {!_es_pieza() && (
                                      <div>
                                        <span className="style-letra-info-prod  ">
                                          {" "}
                                          Total{" "}
                                        </span>{" "}
                                      </div>
                                    )}
                                    {_es_pieza() && (
                                      <div>
                                        <span className="style-letra-info-prod  ">
                                          {" "}
                                          Total estimado{" "}
                                        </span>{" "}
                                      </div>
                                    )}
                                    <div className="listado flex    justify-start  ">
                                      <div className="style-letra-info-prod  style-texto-total  ">
                                        {producto.Moneda}
                                        {". "}
                                        {formatNumber(total_estimado)}
                                      </div>

                                      {producto.ConOferta && (
                                        <div className="bg-hiperorange text-white    texto_ahorrado_info_prod  d-inline-block py-2    font-bold">
                                          Bs. {formatNumber(ahorrado)}&nbsp;
                                          Ahorrado
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )}
                                {producto.EsProductoReceta && (
                                  <div className="bg-[#17998c] rounded-lg p-3 flex items-center space-x-5 shadow-md">
                                    <FontAwesomeIcon
                                      icon={faStaffSnake}
                                      className="text-white text-xl sm:text-2xl"
                                    />
                                    <span className="text-white text-sm sm:text-base md:text-lg font-medium leading-tight">
                                      {mensajeProductoReceta ?? ""}
                                    </span>
                                  </div>
                                )}
                                <div className=" text-hiperorange hover:text-hiperorange_hover ">
                                  <span
                                    className="cursor-pointer font-bold "
                                    onClick={() => show_ventana_favorito()}
                                  >
                                    {" "}
                                    Agregar a tus listas de compras{" "}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <Modal
                            isOpen={modal}
                            ariaHideApp={false}
                            onRequestClose={endModal}
                            className="fixed inset-0 flex items-center justify-center p-2 md:p-4 w-full md:max-w-md  md:mx-auto focus-visible:outline-none"
                            overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-30"
                            contentLabel="Seleccionar peso"
                          >
                            <div className="w-full flex flex-col h-full md:max-h-[600px]">
                              <div className="w-full bg-white rounded-lg md:rounded-lg h-full overflow-hidden">
                                <div className="flex flex-col h-full">
                                  <div className="bg-hiperorange w-100 relative text-white flex items-center justify-center h-[52px]  content_title">
                                    <h2 className="p-0 m-0 title_carrito_responsivo">
                                      Seleccione el Peso
                                    </h2>
                                    <div className="absolute right-2 ">
                                      <ButtonClose
                                        className="text-white bg-transparent"
                                        onClick={() => endModal()}
                                      />
                                    </div>
                                  </div>

                                  <div className="bg-white overflow-y-auto grid grid-cols-2 w-full h-full gap-2 p-4">
                                    {pesajes.map((pesaje, index) => (
                                      <button
                                        key={`peso-${index}`}
                                        className="w-full bg-[#EEEEEE] p-2 rounded hover:bg-gray-300 font-semibold text-xl flex items-center justify-center"
                                        onClick={() => set_peso_pieza(pesaje)}
                                      >
                                        {pesaje.name}
                                      </button>
                                    ))}
                                  </div>
                                  <div className="border-t-2 border-[#EEEEEE] p-2 flex justify-center sticky bottom-0">
                                    <div className="flex border-[3px] border-hiperorange p-1 rounded">
                                      <button
                                        disabled={cantidad <= 0}
                                        className="w-10 h-10 bg-hiperorange hover:bg-hiperorange_hover text-white rounded flex items-center justify-center disabled:cursor-not-allowed"
                                        onClick={() =>
                                          actualizar_item(0.1, "decremento")
                                        }
                                      >
                                        <FontAwesomeIcon icon={faMinus} />
                                      </button>
                                      <div className="mx-2 flex items-center justify-center min-w-10">
                                        <span>{cantidad_peso}</span>
                                      </div>
                                      <button
                                        className="w-10 h-10 bg-hiperorange hover:bg-hiperorange_hover text-white rounded flex items-center justify-center"
                                        onClick={() =>
                                          actualizar_item(0.1, "incremento")
                                        }
                                      >
                                        <FontAwesomeIcon icon={faPlus} />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Modal>

                          <Modal
                            isOpen={openModalSucursal}
                            appElement={document.getElementById("root")}
                            onRequestClose={handleCloseModalSucursal}
                            className="fixed inset-0 flex items-center justify-center p-2 md:p-4 max-w-lg w-full md:w-auto md:max-w-3xl mx-auto focus-visible:outline-none"
                            overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-30"
                            contentLabel="Example Modal"
                            key={"sdfsdg"}
                          >
                            <Sucursal onCloseModal={handleCloseModalSucursal} />
                          </Modal>
                        </div>
                      ) : (
                        <div className="flex items-center justify-center h-[50vh] flex-col gap-5">
                          <FontAwesomeIcon
                            className="text-gray-500"
                            icon={faCartCircleXmark}
                            size="6x"
                          />
                          <div className="flex flex-col gap-2">
                            <div className="text-gray-500 text-lg md:text-xl text-center">
                              Producto no disponible en la sucursal seleccionada
                            </div>
                            <MensajeTelefonoProductoDisponible />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  <Modal
                    isOpen={modal_favorito}
                    ariaHideApp={false}
                    onRequestClose={closeModalFavorito}
                    className="fixed inset-0 flex items-center justify-center p-2 md:p-4 w-full md:max-w-md  md:mx-auto focus-visible:outline-none"
                    overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-30"
                    contentLabel="favorito"
                  >
                    <Favorito
                      idProducto={producto?.IdProducto}
                      IdSucursal={sucursal?.IdSucursal}
                      cantidad={cantidad}
                      pesos={pesos}
                      onBlur={fueraFoco}
                      delete_producto={false}
                      ps_closeModalFavorito={closeModalFavorito}
                    ></Favorito>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </ZoomProvider>
    </>
  );
};

const mapStateToProps = (state) => ({
  carrito: state.Carrito.carrito,
  producto: state.Producto.producto,
  isLoading: state.Producto.isFetching,
  sucursal: state.BranchOffice.sucursal,
  sucursales: state.BranchOffice.sucursales,
  tipo_entrega: state.BranchOffice.tipo_entrega,
  direccion: state.Location.direccion,
  producto_delete: state.Carrito.producto_delete,
  profile: state.Profile.profile,
});

export default connect(mapStateToProps, {
  limpiar_item_producto,
  get_producto_item,
  set_peso_al_carrito,
  add_al_carrito,
  inc_al_carrito,
  dec_al_carrito,
  remove_al_carrito,
  actualizar_item_carrito,
  set_all_prod_category,
})(InformacionProducto);
