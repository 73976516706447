import React, { useEffect, useLayoutEffect } from "react";
import { Provider } from "react-redux";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import ProtectedRoute from "./components/Utils/ProtectedRoute";
import ProtectedUserRegistered from "./components/Utils/ProtectedUserRegistered";
import Cuenta from "./containers/Cuenta";
import Home from "./containers/Home";
import HomeCategoria from "./containers/HomeCategoria";
import HomeCategoriaProducto from "./containers/HomeCategoriaProducto";
import HomeSeccion from "./containers/HomeSeccion";
import InformacionProductoCarrito from "./containers/InformacionProductoCarritoNew";
import InformacionProductoNew from "./containers/InformacionProductoNew";
import InformacionProductoNew2 from "./containers/InformacionProductoNew2";
import DetalleListaCompra from "./containers/ListaCompra/DetalleListaCompra";
import DetalleListaCompraCompartida from "./containers/ListaCompra/DetalleListaCompraCompartida";
import PurchaseList from "./containers/ListaCompra/ListaCompra";
import NuevaListaCompra from "./containers/ListaCompra/NuevaListaCompra";
import InformacionProductoPieza from "./containers/ListaCompra/ProductoPiezaNew";
import ListaMisTarjetas from "./containers/MisTarjetas/ListaMisTarjetas.jsx";
import NuevaTarjeta from "./containers/MisTarjetas/NuevaTarjeta";
import SearchProducto from "./containers/SearchProducto";
import TokenMomentaneo from "./containers/auth/TokenMomentaneo";
import ConfirmacionPedido from "./containers/cart/ConfirmacionPedido";
import DatosEntrega from "./containers/cart/DatosEntrega";
import DetallePedido from "./containers/cart/DetallePedido";
import Entrega from "./containers/cart/Entrega";
import EntregaCompraRap from "./containers/cart/EntregaCompraRapida";
import MyCart from "./containers/cart/MyCart";
import Escanear from "./containers/compras/Escanear";
import Error404 from "./containers/errors/Error404";
import MisPedidos from "./containers/pedido/MisPedidos";
import store from "./store";
//import ActualizarDireccion from "./containers/direccion/ActualizarDireccion";
import { onMessage } from "@firebase/messaging";
import { useLocalStorage } from "react-use";
import Swal from "sweetalert2";
import { getMessagingInstance } from "./Firebase";
import BuscarPorCodigoBarra from "./containers/Search/BuscarPorCodigoBarra";
import ProductoScroll from "./containers/productoscroll";
import SucursalesPage from "./containers/sucursal/sucursales-page.jsx";
import { CustomerBagsPage } from "./modules/hiper-puntos/ui/pages/customer-bags-page";
import { CustomerBagHistoryPage } from "./modules/hiper-puntos/ui/pages/customer-bag-history-page";
import useGoogleAnalytics from "./modules/track-events/ui/hooks/google-analytics/use-google-analytics";
import useFacebookPixel from "./modules/track-events/ui/hooks/facebook-pixel/use-facebook-pixel";

const TIMER = 5000;

const Wrapper = ({ children }) => {
  const location = useLocation();
  useGoogleAnalytics();

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

function App() {
  useFacebookPixel();

  const [direccion, setUser] = useLocalStorage("direccion");
  const [branch, setBranch] = useLocalStorage("sucursal");

  useEffect(() => {
    const enableMessaging = async () => {
      const messaging = await getMessagingInstance();
      if (messaging) {
        onMessage(messaging, (payload) => {
          Swal.fire({
            icon: "success",
            title: "Éxito!",
            text: payload.notification.body,
            allowEscapeKey: false,
            timer: TIMER,
            timerProgressBar: true,
            toast: true,
            showConfirmButton: false,
            position: "bottom-end",
          });
        });
      }
    };
    enableMessaging();
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Wrapper>
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute canAtivate={direccion} branch={branch} />
              }
            >
              <Route path="/:ciudadp/:sucursalp" component={App}>
                <Route path="*" element={<Error404 />} />
                <Route exact path="" element={<Home />} />
                <Route
                  exact
                  path="producto/:productoId/:productoSlug"
                  element={<InformacionProductoNew />}
                />
                <Route
                  exact
                  path="producto-test/:productoId/:productoSlug"
                  element={<InformacionProductoNew />}
                />
                <Route
                  exact
                  path="producto-test2/:productoId/:productoSlug"
                  element={<InformacionProductoNew2 />}
                />

                <Route
                  exact
                  path="categoria/:IdCategoria/:subcategoriaId"
                  element={<HomeCategoria />}
                />
                <Route
                  exact
                  path="categoria/:IdCategoria"
                  element={<HomeCategoria />}
                />
                <Route
                  exact
                  path="categoria/productos/:filter"
                  element={<HomeCategoriaProducto />}
                />
                <Route
                  exact
                  path="busqueda-producto"
                  element={<SearchProducto />}
                />
                <Route
                  exact
                  path="busqueda-producto-g/:IdCategoria"
                  element={<SearchProducto />}
                />
                <Route
                  exact
                  path="busqueda-producto-g/:IdCategoria/:producto"
                  element={<SearchProducto />}
                />
                <Route
                  exact
                  path="seccion/:SeccionId?"
                  element={<HomeSeccion />}
                />
                <Route
                  exact
                  path="carrito/:productoId/:IdMarket/:IdSucursal"
                  element={<InformacionProductoCarrito />}
                />
                <Route
                  exact
                  path="lista-compra-compartida/:HashListaCompra"
                  element={<DetalleListaCompraCompartida />}
                />
                <Route
                  element={<ProtectedUserRegistered></ProtectedUserRegistered>}
                >
                  <Route
                    exact
                    path="lista-compras"
                    element={<PurchaseList />}
                  />
                  <Route
                    exact
                    path="detalle-lista-compra/:IdListaCompra"
                    element={<DetalleListaCompra />}
                  />

                  <Route
                    exact
                    path="nueva-lista-compra"
                    element={<NuevaListaCompra />}
                  />
                  <Route exact path="escanearCodigo" element={<Escanear />} />
                  <Route exact path="cuenta" element={<Cuenta />} />
                  <Route
                    exact
                    path="mis-tarjetas"
                    element={<ListaMisTarjetas />}
                  />
                  <Route
                    exact
                    path="nueva-tarjeta"
                    element={<NuevaTarjeta />}
                  />
                  <Route exact path="mis-pedidos" element={<MisPedidos />} />
                  <Route
                    exact
                    path="pedido-detalle/:id"
                    element={<DetallePedido />}
                  />
                  <Route
                    exact
                    path="lista-compra/detalle-producto/:listaCompraId/:productoId"
                    element={<InformacionProductoPieza />}
                  />

                  <Route
                    exact
                    path="hiper-puntos"
                    element={<CustomerBagsPage />}
                  />

                  <Route
                    exact
                    path="hiper-puntos/bolsa/:idBolsa"
                    element={<CustomerBagHistoryPage />}
                  />
                </Route>
              </Route>

              {/* protected  autenticacion */}
              <Route exact path="/carrito" element={<MyCart />} />
              <Route exact path="/entrega" element={<Entrega />} />
              <Route
                exact
                path="/entrega-compra-rapida"
                element={<EntregaCompraRap />}
              />
              <Route exact path="/datos-entrega" element={<DatosEntrega />} />
              <Route
                exact
                path="/confirmacion-pedido"
                element={<ConfirmacionPedido />}
              />

              {/*<Route
              exact
              path="/actualizar-direccion/:locationId"
              element={<ActualizarDireccion />}
            />*/}
              <Route
                exact
                path="/producto-scroll"
                element={<ProductoScroll />}
              />
              <Route
                exact
                path="/buscar-producto-por-codigo-barra"
                element={<BuscarPorCodigoBarra />}
              />
            </Route>

            <Route exact path="/token" element={<TokenMomentaneo />} />
            <Route exact path="/sucursales" element={<SucursalesPage />} />
            <Route path="*" element={<Error404 />} />
          </Routes>
        </Wrapper>
      </Router>
    </Provider>
  );
}

export default App;
