import React, { useState, useEffect, useMemo } from "react";
import Layout from "../../hocs/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-modal";
import ItemDetalleListaCompra from "../../widgets/item_detalle_lista_compra";
import { connect } from "react-redux";
import {
  get_detalle_lista_compra,
  actualizar_detalles_lista_compra,
  actualizar_lista_carrito,
  inc_peso_carrito_lista_compra,
  eliminar_product_lista_compra,
  limpiar_data_lista_compra,
} from "../../redux/actions/shoppingList";
import { set_id_list_compra } from "../../redux/actions/shoppingList";
import { useParams } from "react-router";
import {
  faCartCircleXmark,
  faCircleXmark,
  faMinus,
  faPlus,
  faSquareMinus,
  faSquarePlus,
} from "@fortawesome/pro-solid-svg-icons";
import Swal from "sweetalert2";
import "../../assets/styles/globalColorPrincipal.css";
import "../../assets/styles/stylePageGlobals.scss";
import "../../assets/styles/responsive.scss";
import Comprarapida from "../../components/modal/compra_rapida";
import {
  add_al_carrito,
  store_carrito_data,
  update_carrito,
} from "../../redux/actions/carrito";
import MiCarrito from "../../components/modal/mi_carrito";
import ButtonText from "../../widgets/button_text";
import Alert from "../../components/Swalert";
import LoaderSpinner from "../../widgets/loaderSpinner";
import ContenidoCompartir from "./Partials/ModalCompartirLista";
import { colorEnUmbralDeBlanco } from "../../components/Utils/utils-colores";
import classNames from "classnames";
import { useThousandSeparator } from "../../hooks/useThousandSeparator";
import { useQueryParams } from "../../hooks/useQueryParams";
import { Helmet } from "react-helmet-async";
import ButtonClose from "../../widgets/button_close";
import { useIsDefaultStore } from "../../hooks/useIsDefaultStore";

const customStylesFondo = {
  overlay: {
    backgroundColor: "#000000a6",
    zIndex: "1",
  },
  content: {
    top: "54%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    borderRadius: "10px",
    border: "none",
    overflow: "visible",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px",
  },
};

const customStyles2 = {
  overlay: {
    backgroundColor: "#000000a6",
    zIndex: "10",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    borderRadius: "0 px",
    border: "none",
    background: "none",
    padding: "0px 0px 0px 0px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "none",
    height: "calc(100% - 32px)",
  },
};

const customStyles = {
  overlay: {
    backgroundColor: "#000000a6",
    zIndex: "1",
    minHeight: "45rem",
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    borderRadius: "0.75rem",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "visible",
  },
};
const pesajes = [
  { name: "500g", peso: "500" },
  { name: "1Kg", peso: "1000" },
  { name: "2Kg", peso: "2000" },
  { name: "3Kg", peso: "3000" },
  { name: "5Kg", peso: "5000" },
  { name: "10Kg", peso: "10000" },
  { name: "15Kg", peso: "15000" },
  { name: "20Kg", peso: "20000" },
  { name: "25Kg", peso: "25000" },
  { name: "30Kg", peso: "30000" },
  { name: "35Kg", peso: "35000" },
  { name: "40Kg", peso: "40000" },
  { name: "45Kg", peso: "45000" },
  { name: "50Kg", peso: "50000" },
];
const DetalleListaCompra = ({
  get_detalle_lista_compra,
  shopping_list_detalle,
  sucursal,

  carrito_lista_compra,
  actualizar_lista_carrito,
  set_id_list_compra,
  add_al_carrito,
  carritos,

  actualizar_detalles_lista_compra,
  inc_peso_carrito_lista_compra,
  eliminar_product_lista_compra,
  productos_delete_lista_compra,
  cambio_carrito,
  list_carrito,
  store_carrito_data,
  update_carrito,
  direccion,
  estado_cambios,
  limpiar_data_lista_compra,
  peticion,
}) => {
  const {
    paramValue: openModalCarrito,
    updateParamValue: setOpenModalCarrito,
    deleteParamValue: setCloseModalCarrito,
  } = useQueryParams("abrir-carrito-lista");
  const { numberFormatter } = useThousandSeparator();
  const params = useParams();
  const IdListaCompra = params.IdListaCompra;

  let IdSucursal = sucursal.IdSucursal;

  // const [openModalCarrito, setOpenModalCarrito] = useState(false);
  const [modalComprarapida, setCarritoComprarapida] = useState(false);
  const [modalPeso, setModalPeso] = React.useState(false);
  const [costoTotal, setCostoTotal] = useState(0);
  const [ahorradoTotal, setAhorradoTotal] = useState(0);
  const [verifyCambio, setVerifyCambio] = useState(false);
  const [todos, setTodos] = useState([]);
  const [objectProducto, setObjectProducto] = useState();
  const [cantidadPeso, setCantidadPeso] = useState();

  const [modalShare, setModalShare] = React.useState(false);

  useEffect(() => {
    fetchData(IdListaCompra, IdSucursal);
  }, [IdListaCompra, IdSucursal]);

  useEffect(() => {
    return () => {
      setCostoTotal(0);
      setAhorradoTotal(0);
    };
  }, []);

  useEffect(() => {
    calcularTotal();
  }, [carrito_lista_compra]);

  useEffect(() => {
    return () => {
      guardarBD1();
    };
  }, []);

  useEffect(() => {
    if (estado_cambios) {
      setVerifyCambio(true);
    }
  }, [estado_cambios]);

  useEffect(() => {
    verifyCambioListaCarrito();
  }, [costoTotal]);

  const openModalShare = () => setModalShare(true);
  const closeModalShare = () => setModalShare(false);

  const openModalPeso = () => setModalPeso(true);
  const closeModalPeso = () => setModalPeso(false);

  const abrirModalCarrito = () => setOpenModalCarrito("1");
  const cerraModalCarrito = () => {
    setCloseModalCarrito();
  };

  const startComprarapida = () => {
    if (
      carrito_lista_compra?.filter(
        (product) => product.Checked === true && product.Vigente === true
      ).length === 0
    ) {
      mensaje_alert(
        "No tiene productos seleccionados para realizar la compra."
      );
      return;
    }

    if (validar_monto_minimo()) {
      setCarritoComprarapida(true);
    } else {
      var monto = sucursal.ImpMinimoPedido - costoTotal;
      var texto =
        "Faltan Bs. " +
        formatPrice(monto) +
        " para completar el mínimo de compra.";
      mensaje_alert(texto);
    }
  };

  const validar_monto_minimo = () => {
    if (costoTotal >= sucursal.ImpMinimoPedido) {
      return true;
    }
    return false;
  };

  const endCompraRapida = () => setCarritoComprarapida(false);

  const fetchData = async (IdLista, IdSucursal) => {
    try {
      const parametro = { Id: IdLista, Sucursal: IdSucursal };
      await get_detalle_lista_compra(parametro);
    } catch (e) {}
  };

  const verifyCambioListaCarrito = () => {
    let resp = false;
    if (verifyShoopingListDetalle()) {
      let cantidad_axu = 0;
      let cantidad_act = 0;

      shopping_list_detalle.Productos.map((pso) => {
        cantidad_axu += pso.Cantidad;
      });
      carrito_lista_compra.map((ps) => {
        cantidad_act += ps.Cantidad;
      });
      if (parseFloat(cantidad_axu) !== parseFloat(cantidad_act)) {
        setVerifyCambio(true);
        resp = true;
      } else {
        resp = false;
        setVerifyCambio(false);
      }
    }
    return resp;
  };

  const guardarBD1 = async () => {
    let listaux = JSON.parse(localStorage.getItem("item_lista_compra"));
    let datos = {
      Id: listaux.Id,
      Descripcion: listaux.Descripcion,
      Color: listaux.Color,
    };
    let IdSucursal = sucursal.IdSucursal;
    let productoaux =
      JSON.parse(localStorage.getItem("carrito_lista_compra")) ?? [];
    let prod_delete =
      JSON.parse(localStorage.getItem("productos_delete_lista_compra")) ?? [];
    await actualizar_detalles_lista_compra(
      datos,
      productoaux,
      prod_delete,
      IdSucursal
    );
    // await limpiar_data_lista_compra();
  };

  const guardarBD = async () => {
    if (estado_cambios) {
      const datos = {
        Id: shopping_list_detalle.Id,
        Descripcion: shopping_list_detalle.Descripcion,
        Color: shopping_list_detalle.Color,
      };
      let IdSucursal = sucursal.IdSucursal;
      await actualizar_detalles_lista_compra(
        datos,
        carrito_lista_compra,
        productos_delete_lista_compra,
        IdSucursal
      );
      // await limpiar_data_lista_compra();
    }
  };

  const actualizarDato = (productId, operacion) => {
    if (operacion === "incremento") {
      setTodos(
        todos.map((product) => {
          if (product.IdProducto === productId) {
            return {
              ...product,
              Cantidad: product.Cantidad + 1,
            };
          } else {
            return product;
          }
        })
      );
    } else if (operacion === "decremento") {
      setTodos(
        todos.map((product) => {
          if (product.IdProducto === productId && product.Cantidad >= 1) {
            return {
              ...product,
              Cantidad: product.Cantidad - 1,
            };
          } else {
            return product;
          }
        })
      );
    }
  };

  const handleCheckedProducto = (productoId, nextChecked) => {
    setTodos(
      carrito_lista_compra.map((product) => {
        if (product.IdProducto === productoId) {
          // Create a *new* object with changes
          return { ...product, Checked: nextChecked };
        } else {
          // No changes
          return product;
        }
      })
    );
  };

  const viewProductoPeso = (producto) => {
    setObjectProducto(producto);
    updateCantidadPeso(producto.Cantidad);
  };

  const updateCantidadPeso = (cantidad) => {
    setCantidadPeso(convertKgToGramos(cantidad));
  };

  const convertKgToGramos = (datoKg) => {
    return datoKg * 1000;
  };

  const convertGramosToKg = (datoKg) => {
    return datoKg / 1000;
  };

  const convertToRounded = (num) => {
    return Math.round(num).toFixed(1);
  };

  const calcularTotal = () => {
    const countSuma = carrito_lista_compra.map((product) => {
      if (product.Checked === true && product.Vigente === true) {
        let result = 0;
        let total_estimado = 0;
        if (product.Fraccionado && product.PesoDefinido) {
          product.Pesos.forEach((peso) => {
            result += peso.Piezas * peso.PesoAproxMax;
          });
        } else {
          result = product.Cantidad;
        }
        let precio_producto = product.ConOferta
          ? product.PrecioOferta
          : product.PrecioVenta;
        total_estimado = result * precio_producto;
        return parseFloat(total_estimado.toFixed(2));
      }
      return 0;
    });

    const countSumaAhorrado = carrito_lista_compra.map((product) => {
      let resultado = 0;
      if (
        product.Checked === true &&
        product.Vigente === true &&
        product.Cantidad > 0 &&
        product.ConOferta === true
      ) {
        let precio_venta = product.ConOferta
          ? product.PrecioOferta
          : product.PrecioVenta;

        let cantidad_pieza = 0;
        if (product.Fraccionado && product.PesoDefinido) {
          product.Pesos.forEach((peso) => {
            cantidad_pieza += peso.Piezas * peso.PesoAproxMax;
          });
          let total_sin_oferta = cantidad_pieza * product.PrecioOriginal;
          let total_oferta = cantidad_pieza * precio_venta;
          resultado = total_sin_oferta - total_oferta;
        } else {
          let rest = product.PrecioOriginal - product.PrecioOferta;
          // return (product.Cantidad * rest);

          resultado = product.Cantidad * rest;
        }

        return resultado;
        // return (product.Cantidad * rest);
      }
      return 0;
    });
    let sumaPrecios = 0;
    let sumaAhorrado = 0;

    if (countSuma[0] !== undefined) {
      sumaPrecios = countSuma.reduce((prev, next) => prev + next);
    }
    if (countSumaAhorrado[0] !== undefined) {
      sumaAhorrado = countSumaAhorrado.reduce((prev, next) => prev + next);
    }

    setCostoTotal(sumaPrecios);
    setAhorradoTotal(sumaAhorrado);
  };

  const verifyShoopingListDetalle = () => {
    if (shopping_list_detalle !== undefined && shopping_list_detalle !== null) {
      return true;
    }
    return false;
  };

  const verifyDetalleProductosVacio = () => {
    if (
      carrito_lista_compra !== undefined &&
      carrito_lista_compra !== null &&
      carrito_lista_compra.length === 0
    ) {
      return true;
    }
    return false;
  };

  const verifyDetalleProductosOk = () => {
    if (
      carrito_lista_compra !== undefined &&
      carrito_lista_compra !== null &&
      carrito_lista_compra.length > 0
    ) {
      return true;
    }
    return false;
  };

  const mensaje_alert = (text) => {
    Swal.fire({
      icon: "warning",
      text: text,
      showConfirmButton: true,
      confirmButtonText: "Cerrar",
      confirmButtonColor: "#32338E",
    });
  };

  const preguntar = (titulo, operacion) => {
    Swal.fire({
      title: titulo,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#32338E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        operacion();
      }
    });
  };

  const agregar_al_carrito = async () => {
    carrito_lista_compra.forEach((producto) => {
      if (producto.Checked) {
        add_al_carrito(producto, Number(producto.Cantidad));
      }
    });

    if (verifyCambio) {
      await guardarBD();
    }
    abrirModalCarrito();
  };

  const confirmar_traspaso = () => {
    if (
      carrito_lista_compra?.filter(
        (product) => product.Checked === true && product.Vigente === true
      ).length === 0
    ) {
      mensaje_alert(
        "No tiene productos seleccionados para realizar la compra."
      );
      return;
    }
    preguntar(
      "Esta acción modificará el carrito de compra, ¿Desea continuar?",
      agregar_al_carrito
    );
  };

  const redondeo = (num, dec) => {
    return Number(num.toFixed(dec));
  };

  const actualizarCantidadCarrito = (producto, operacion, tipo_cantidad) => {
    if (tipo_cantidad === "es_peso") {
      actualizarCantidadPeso(producto, operacion);
    } else if (tipo_cantidad === "es_pieza") {
    } else if (tipo_cantidad === "normal") {
    }
  };

  const actualizarCantidadPeso = (producto, operacion) => {
    let cantidad = Number(producto.Cantidad);
    let resultKg = 0;
    if (operacion === "incremento") {
      resultKg = redondeo(cantidad + 0.1, 2);
    } else if (operacion === "decremento") {
      resultKg = redondeo(cantidad - 0.1, 2);
    }
    actualizarPesoDeModal(producto, resultKg);

    updateCantidadPeso(resultKg);
  };

  const set_peso = (pesaje) => {
    let pesoKg = convertGramosToKg(Number(pesaje.peso));
    updateCantidadPeso(pesoKg);
    actualizarPesoDeModal(objectProducto, pesoKg);
  };

  const actualizarPesoDeModal = async (producto, peso_kg) => {
    await inc_peso_carrito_lista_compra(producto, peso_kg);
    calcularTotal();
  };

  const eliminarProduct = (id_producto) => {
    eliminar_product_lista_compra(id_producto, IdSucursal);
  };

  const endCarrito = async () => {
    if (cambio_carrito) {
      for (var item of list_carrito.SubPedidos) {
        let params = {
          IdMarket: item.IdMarket,
          IdSucursal: item.Sala.Id,
          IdTipoEntrega: item.IdTipoEntrega,
          IdDireccion: direccion.Id,
        };
        let carritos_aux = item.Productos;
        if (item.Sala.Id === sucursal.IdSucursal) {
          await update_carrito(carritos_aux);
        }
        await store_carrito_data(params, carritos_aux, []);
      }
    }

    cerraModalCarrito(false);
  };

  const afterOpenModal = () => {};

  const formatPrice = (value) => {
    return numberFormatter.format(value);
  };

  const colorEsEnUmbralDeBlanco = colorEnUmbralDeBlanco(
    shopping_list_detalle?.Color,
    180
  );

  const _cantidad_peso = () => {
    return cantidadPeso?.toFixed(0) + "g";
  };
  const { isDefaultStore } = useIsDefaultStore();

  return (
    <>
      <Helmet>
        <title>{`${shopping_list_detalle?.Descripcion || ""} | ${
          isDefaultStore ? "Hipermaxi" : sucursal?.Descripcion || ""
        }`}</title>
        {/* <meta name="“robots”" content="noindex" /> */}
      </Helmet>
      <Layout>
        <div className="content_info pt-2 my-4">
          <Alert></Alert>

          <div className="container">
            <div className="w-full pb-2">
              <div className="flex flex-col gap-2 md:flex-row items-center">
                <h3 className="title font-medium text-1 text-xl md:text-2xl w-full">
                  Mi lista de compras
                </h3>
                <div className="w-full md:w-auto gap-2 items-center flex ">
                  <ButtonText
                    label="Compartir lista"
                    principal={false}
                    className="bg-hiperorange hover:bg-hiperorange_hover text-white text-center min-w-40"
                    onClick={openModalShare}
                  />
                </div>
              </div>
            </div>
            <div className="inline-block mb-2">
              {verifyShoopingListDetalle() === true ? (
                <div
                  className={classNames(
                    "px-2 py-0.5 rounded-lg  border-[0.1rem] text-sm md:text-base",
                    {
                      "text-white": !colorEsEnUmbralDeBlanco,
                      "text-black": colorEsEnUmbralDeBlanco,
                    }
                  )}
                  style={{
                    backgroundColor: shopping_list_detalle.Color,
                    borderColor: colorEsEnUmbralDeBlanco
                      ? "#000000"
                      : shopping_list_detalle.Color,
                  }}
                >
                  <div>
                    {`${shopping_list_detalle.Descripcion} ${
                      verifyDetalleProductosOk()
                        ? `(${carrito_lista_compra.length} producto${
                            carrito_lista_compra.length > 1 ? "s" : ""
                          })`
                        : ""
                    }`}
                  </div>
                </div>
              ) : (
                <div
                  className={
                    "px-2 py-0.5 text-gray-400 rounded-lg  border-[0.1rem]"
                  }
                >
                  <p className="text-gray-400">Vacío</p>
                </div>
              )}
            </div>
            <div className="w-full contenedor_flex contenedor_altura gap-4">
              <div className="md:w-[65%]  contenedor_mobile">
                <div className="w-100 bg-white py-1 border subcontenedor_mobile shadow-md rounded-lg">
                  <div className=" w-100 container_scroll md:h-[40rem] md:overflow-y-auto">
                    {verifyDetalleProductosOk() ? (
                      carrito_lista_compra.map((item_shopping_list) => (
                        <ItemDetalleListaCompra
                          key={item_shopping_list.IdProducto}
                          ps_object_lista_compra={shopping_list_detalle}
                          ps_producto={item_shopping_list}
                          ps_updateDato={actualizarDato}
                          ps_handleCheckedProducto={handleCheckedProducto}
                          ps_calcularTotal={calcularTotal}
                          ps_openModalPeso={openModalPeso}
                          ps_viewProductoPeso={viewProductoPeso}
                          ps_actualizarCantidadCarrito={
                            actualizarCantidadCarrito
                          }
                          ps_eliminarProduct={eliminarProduct}
                          ps_idSucursal={IdSucursal}
                        ></ItemDetalleListaCompra>
                      ))
                    ) : (
                      <>
                        {peticion ? (
                          <LoaderSpinner bool={true} />
                        ) : (
                          verifyDetalleProductosVacio() && (
                            <div className="flex items-center justify-center h-[50vh] flex-col gap-5">
                              <FontAwesomeIcon
                                className="text-gray-500"
                                icon={faCartCircleXmark}
                                size="6x"
                              />
                              <div className="text-gray-500 text-lg md:text-xl text-center">
                                No hay productos para mostrar
                              </div>
                            </div>
                          )
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="sticky bottom-0 left-0 right-0 md:static md: md:w-[35%] mx-[-12px] md:my-0 md:mb-4">
                <div className="content_card_footer bg-white text-darck  px-3 py-2 md:p-4 mb-0 border rounded-t-lg md:rounded-lg shadow-md">
                  <div className="flex flex-col gap-1 mb-1">
                    <div className="grid grid-cols-6 justify-between text-sm md:text-base px-2 rounded-full text-white font-bold">
                      <span className="col-start-1 col-span-3 lg:col-start-2 lg:col-span-2 rounded-bl-full rounded-tl-full   bg-hiperorange  text-right py-1">
                        Ahorro:
                      </span>
                      <span className="col-span-3 bg-hiperorange text-right rounded-br-full rounded-tr-full pr-2 py-1">
                        Bs. {formatPrice(ahorradoTotal)}
                      </span>
                    </div>
                    <div className="grid grid-cols-6 justify-between text-sm md:text-base px-2">
                      <span className="col-start-1 col-span-3 text-right">
                        Monto mínimo de compra:
                      </span>
                      <span className="col-span-3 text-right pr-2">
                        Bs. {formatPrice(sucursal.ImpMinimoPedido)}
                      </span>
                    </div>
                    <div className="grid grid-cols-6  justify-between font-bold text-sm md:text-base px-2">
                      <span className="col-start-1 col-span-3 text-right ">
                        Total:
                      </span>
                      <span className="col-span-3 text-right pr-2">
                        Bs. {formatPrice(costoTotal)}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center  text-center text-white">
                    <ButtonText
                      label="Agregar a carrito"
                      principal={true}
                      className={
                        "bg-hiperorange_claro mb-2 hover:bg-hiperorange_claro_hover text-black text-sm md:text-base disabled:cursor-not-allowed"
                      }
                      onClick={() => confirmar_traspaso()}
                    />
                    <ButtonText
                      label="Compra rápida"
                      principal={false}
                      className="bg-hiperorange hover:bg-hiperorange_hover text-white text-center text-sm md:text-base disabled:cursor-not-allowed"
                      onClick={() => startComprarapida()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          isOpen={modalPeso}
          ariaHideApp={false}
          onRequestClose={closeModalPeso}
          className="fixed inset-0 flex items-center justify-center p-2 md:p-4 w-full md:max-w-md  md:mx-auto focus-visible:outline-none"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-30"
          contentLabel="Seleccionar peso"
        >
          <div className="w-full flex flex-col h-full md:max-h-[600px]">
            <div className="w-full bg-white rounded-lg md:rounded-lg h-full overflow-hidden">
              <div className="flex flex-col h-full">
                <div className="bg-hiperorange w-100 relative text-white flex items-center justify-center h-[52px]  content_title">
                  <h2 className="p-0 m-0 title_carrito_responsivo">
                    Seleccione el Peso
                  </h2>
                  <div className="absolute right-2 ">
                    <ButtonClose
                      className="text-white bg-transparent"
                      onClick={() => closeModalPeso()}
                    />
                  </div>
                </div>
                <div className="bg-white overflow-y-auto grid grid-cols-2 w-full h-full gap-2 p-4">
                  {pesajes.map((pesaje, index) => (
                    <button
                      key={`peso-${index}`}
                      className="w-full bg-[#EEEEEE] p-2 rounded hover:bg-gray-300 font-semibold text-xl flex items-center justify-center"
                      onClick={() => set_peso(pesaje)}
                    >
                      {pesaje.name}
                    </button>
                  ))}
                </div>
                <div className="border-t-2 border-[#EEEEEE] p-2 flex justify-center sticky bottom-0">
                  <div className="flex border-[3px] border-hiperorange p-1 rounded">
                    <button
                      disabled={cantidadPeso <= 0}
                      className="w-8 h-8 bg-hiperorange hover:bg-hiperorange_hover text-white rounded flex items-center justify-center disabled:cursor-not-allowed"
                      onClick={() =>
                        actualizarCantidadPeso(objectProducto, "decremento")
                      }
                    >
                      <FontAwesomeIcon icon={faMinus} />
                    </button>
                    <div className="mx-2 flex  items-center justify-center min-w-10">
                      {/* <span>{cantidad_pieza}</span> */}
                      <span>{_cantidad_peso(cantidadPeso)}</span>
                    </div>
                    <button
                      className="w-8 h-8 bg-hiperorange hover:bg-hiperorange_hover text-white rounded flex items-center justify-center"
                      onClick={() =>
                        actualizarCantidadPeso(objectProducto, "incremento")
                      }
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={modalComprarapida}
          ariaHideApp={false}
          onAfterOpen={afterOpenModal}
          onRequestClose={endCompraRapida}
          className="fixed inset-0 flex items-center justify-center p-2 md:p-4 max-w-lg w-full md:max-w-[1320px] md:mx-auto focus-visible:outline-none"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-30"
          contentLabel="compra_rapida"
          id="shareListFastCart"
        >
          <Comprarapida
            cerrarModal={endCompraRapida}
            productos={carrito_lista_compra?.filter(
              (product) =>
                product.Checked === true &&
                product.Vigente === true &&
                product.Cantidad > 0
            )}
            importeTotal={costoTotal}
            importeAhorrodo={ahorradoTotal}
          />
        </Modal>
        <Modal
          isOpen={!!direccion && openModalCarrito === "1"}
          ariaHideApp={false}
          onAfterOpen={afterOpenModal}
          onRequestClose={endCarrito}
          className="fixed inset-0 flex items-center justify-center p-2 md:p-4 max-w-lg w-full md:max-w-[1320px] md:mx-auto focus-visible:outline-none"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-30"
          contentLabel="carrito"
          id="shareListCart"
        >
          <MiCarrito cerrarModal={endCarrito} />
        </Modal>
        <Modal
          isOpen={modalShare}
          onRequestClose={closeModalShare}
          ariaHideApp={false}
          className="fixed inset-0 flex items-center justify-center p-2 md:p-4 w-full md:max-w-sm  md:mx-auto focus-visible:outline-none"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-30"
          contentLabel="share"
        >
          <ContenidoCompartir
            listaCompra={shopping_list_detalle}
            ps_closeModal={closeModalShare}
          ></ContenidoCompartir>
        </Modal>
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => ({
  sucursal: state.BranchOffice.sucursal,
  shopping_list_detalle: state.Shopping_list.shopping_list_detalle,
  carrito_lista_compra: state.Shopping_list.carrito_lista_compra,
  carritos: state.Carrito.carrito,
  productos_delete_lista_compra:
    state.Shopping_list.productos_delete_lista_compra,
  list_carrito: state.Carrito.list_carrito,
  cambio_carrito: state.Carrito.cambio_carrito,
  direccion: state.Location.direccion,
  estado_cambios: state.Shopping_list.estado_cambios,
  peticion: state.Peticion.peticion,
});

export default connect(mapStateToProps, {
  get_detalle_lista_compra,
  actualizar_detalles_lista_compra,
  actualizar_lista_carrito,
  set_id_list_compra,
  add_al_carrito,
  // get_producto_carrito_lista_compra,
  inc_peso_carrito_lista_compra,
  eliminar_product_lista_compra,
  store_carrito_data,
  update_carrito,
  limpiar_data_lista_compra,
})(DetalleListaCompra);
