import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { googleLogout } from "@react-oauth/google";
import logo from "../../assets/images/login/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBagShopping,
  faCartShopping,
  faCreditCard,
  faHeadset,
  faHeart,
  faMapLocationDot,
  faRightFromBracket,
  faUser,
  faAngleDown,
  faSack,
} from "@fortawesome/pro-solid-svg-icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import "../../assets/styles/globals.scss";
import "../../assets/styles/carrito.scss";
import Direccion from "../modal/direccion";
import Sucursal from "../modal/sucursal";

import { connect } from "react-redux";
import Notificacion from "../home/Notificacion";
import MiCarrito from "../modal/mi_carrito";
import {
  limpiar_carrito,
  limpiar_estado_anonimo,
  store_carrito_data,
  update_carrito,
} from "../../redux/actions/carrito";
import { limpiar_perfil } from "../../redux/actions/profile";
import { limpiar_auth, get_anonimo_token } from "../../redux/actions/auth";
import { limpiar_direccion, get_ubicacion } from "../../redux/actions/location";
import {
  get_sucursales_cerca,
  limpiar_sucursal,
} from "../../redux/actions/branch_office";
import Swal from "sweetalert2";
import Alert from "../Swalert";
import { cleadSwAlert } from "../../redux/actions/swalert";
import { limpiar_data_lista_compra } from "../../redux/actions/shoppingList";
import { clear_pedidos } from "../../redux/actions/pedido";
import ButtonIcon from "../../widgets/button_icon";
import BuscadorGeneral from "../../widgets/BuscadorGeneral";
import RegistroInit from "../../components/modal/registroUser/registro_init";
import classNames from "classnames";
import {
  get_lista_carrito_compras,
  get_carrito_por_sucursal,
} from "../../redux/actions/carrito";
import BuscadorPorCategoria from "../../widgets/BuscadorPorCategoria";
import NavbarMarket from "./NavbarMarket";
import useScreenSize from "../../widgets/useScreenSize";
import { SOCIAL_LOGIN_LOCALSTORAGE_KEY } from "../../containers/auth/constants";
import { getMessagingInstance } from "../../Firebase";
import { deleteToken } from "@firebase/messaging";
import { useQueryParams } from "../../hooks/useQueryParams";
import { HiperPuntosProfile } from "../../modules/hiper-puntos/ui/components/hiper-puntos-profile/hiper-puntos-profile";
const customStyles = {
  overlay: {
    backgroundColor: "#000000a6",
    zIndex: "30",
  },
  content: {
    padding: "0px",
    border: "0px",
    // background: "transparent",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "calc(100% - 32px)",
    overflowY: "auto",
    borderRadius: 8,
  },
};
const customStyles2 = {
  overlay: {
    backgroundColor: "#000000a6",
    zIndex: "10",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    borderRadius: "0 px",
    border: "none",
    background: "none",
    padding: "0px 0px 0px 0px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "none",
    height: "calc(100% - 32px)",
  },
};

function Navbar({
  show_alert,
  redirect,
  cleadSwAlert,
  cambio_carrito,
  list_carrito,
  locations,
  direccion,
  store_carrito_data,
  update_carrito,
  sucursal,
  profile,
  limpiar_perfil,
  limpiar_auth,
  limpiar_direccion,
  limpiar_sucursal,
  limpiar_carrito,
  clear_pedidos,
  carrito,
  url_navigation,
  respuesta_carrito_anonimo,
  respuesta_carrito_user,
  limpiar_estado_anonimo,
  limpiar_data_lista_compra,
  get_ubicacion,
  get_lista_carrito_compras,
  cambio_detalle_carrito,
  get_carrito_por_sucursal,
  producto_delete,
  get_sucursales_cerca,
  get_anonimo_token,
  verHiperPuntosParameter,
}) {
  const {
    paramValue: openModalCarrito,
    updateParamValue: setOpenModalCarrito,
    deleteParamValue: setCloseModalCarrito,
  } = useQueryParams("abrir-carrito");

  // useEffect(() => {
  //   if(openModalCarrito) {
  //     setCloseModalCarrito();
  //   }
  // }, [])

  const { width } = useScreenSize();

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [showMenu, setShow2] = React.useState(true);
  const navigate = useNavigate();
  const { ciudadp, sucursalp, IdCategoria, subcategoriaId } = useParams();
  const [numerocel, setNumerocel] = React.useState(59172682571);
  const [openModalUserRegister, setOpenModalUserRegister] = useState(false);
  const afterOpenModal = () => {};
  const [modalAddress, setAddress] = useState(false);
  const [profileAux, setProfileAux] = useState(null);
  const startAddress = () => setAddress(true);
  const endAddress = () => setAddress(false);

  useEffect(() => {
    if (sucursal) {
      setNumerocel(sucursal.ContactoWhatsapp);
    }
  }, [sucursal]);

  useEffect(() => {
    if (verifyUserAutenticate()) {
      if (locations.length === 0) {
        get_ubicacion();
        const defaultAddress = profile?.Ubicaciones?.find(
          (location) => location.EsPrincipal
        );
        get_sucursales_cerca(defaultAddress);
      }
      if (list_carrito === null) {
        get_carrito_por_sucursal(sucursal);
      }
    }
  }, [profile]);

  useEffect(() => {
    if (show_alert && redirect !== null) {
      if (redirect.type === "modal" && redirect.type_modal) {
        if (redirect.type_title === "direccion") {
          startAddress();
          setTimeout(() => {
            cleadSwAlert();
          }, "1000");
        }
        if (redirect.type_title === "carrito") {
          startCarrito();
          setTimeout(() => {
            cleadSwAlert();
          }, "1000");
        }
      }
    }
  }, [show_alert]);

  const iniciar_session = () => {
    setOpenModalUserRegister(true);
  };
  const [cerrandoSesion, setCerrandoSesion] = useState(false);
  const logout = async (e, close) => {
    e.preventDefault();
    // setCerrandoSesion(true);
    // const valToken = localStorage.getItem(SOCIAL_LOGIN_LOCALSTORAGE_KEY.TOKEN);
    // const valTipoAcceso = localStorage.getItem(SOCIAL_LOGIN_LOCALSTORAGE_KEY.TIPO_ACCESO);
    const messaging = await getMessagingInstance();

    localStorage.clear();
    // if (valToken) {
    //   localStorage.setItem(SOCIAL_LOGIN_LOCALSTORAGE_KEY.TOKEN, valToken);
    // }
    // if (valTipoAcceso) {
    //   localStorage.setItem(SOCIAL_LOGIN_LOCALSTORAGE_KEY.TIPO_ACCESO, valTipoAcceso);
    // }
    // clear_pedidos(true);
    // limpiar_perfil();
    // limpiar_auth();
    // limpiar_direccion();
    // // limpiar_sucursal();
    // limpiar_carrito();
    // limpiar_data_lista_compra();
    googleLogout();
    try {
      if (messaging) await deleteToken(messaging);
    } catch (error) {}
    // await get_anonimo_token();
    // setCerrandoSesion(false);
    close();
    window.location.href = `/${ciudadp}/${sucursalp}`;
  };

  // const [openModalCarrito, setOpenModalCarrito] = useState(false);

  const abrirModalCarrito = () => {
    setOpenModalCarrito("1");
  };

  const cerraModalCarrito = () => {
    setCloseModalCarrito();
  };

  const startCarrito = () => {
    if (!verifyUserAutenticate()) {
      setOpenModalUserRegister(true);
    } else if (direccion) {
      abrirModalCarrito();
    } else {
      Swal.fire({
        title: "Oops...",
        text: "Debe registrar una ubicación",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#0349AB",
        cancelButtonColor: "#d33",
        confirmButtonText: "Registrar dirección",
      }).then((result) => {
        if (result.isConfirmed) {
          startAddress();
          // navigate("/nueva_direccion");
          // navigate("/nueva_direccion_registro");
          // navigate_menu('nueva_direccion_registro');
        }
      });
    }
  };

  const endCarrito = async () => {
    if (respuesta_carrito_user) {
      await limpiar_carrito();
      cerraModalCarrito();
      navigate("/" + ciudadp + "/" + sucursalp);
    } else {
      if (cambio_carrito) {
        for (var item of list_carrito.SubPedidos) {
          let params = {
            IdMarket: item.IdMarket,
            IdSucursal: item.Sala.Id,
            IdTipoEntrega: item.IdTipoEntrega,
            IdDireccion: direccion.Id,
          };
          let carritos_aux = item.Productos;
          if (item.Sala.Id === sucursal.IdSucursal) {
            await update_carrito(carritos_aux);
          }
          await store_carrito_data(params, carritos_aux, producto_delete);
          await get_carrito_por_sucursal(sucursal);
        }
      }
      cerraModalCarrito();
    }
  };

  const endCarritoAnonimo = async () => {
    await limpiar_estado_anonimo();
    cerraModalCarrito();
    navigate("/" + ciudadp + "/" + sucursalp);
  };

  //END CODIGO DE DIRECCION

  useEffect(() => {
    setProfileAux(profile);
  }, [profile]);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const ir_inicio = () => {
    let url_nav = JSON.parse(localStorage.getItem("url_navigation"));
    if (ciudadp && sucursalp) {
      navigate("/" + ciudadp + "/" + sucursalp);
    } else if (url_nav) {
      navigate("/" + url_nav.url_parameters);
    }
  };

  const navigate_menu = async (url_menu, close) => {
    if (verifyUserAutenticate()) {
      let param = await get_url();
      navigate(param + "/" + url_menu);
    } else {
      setOpenModalUserRegister(true);
    }
    close();
  };

  const get_url = async () => {
    let url_nav = JSON.parse(localStorage.getItem("url_navigation"));
    if (ciudadp && sucursalp) {
      return "/" + ciudadp + "/" + sucursalp;
    } else if (url_nav) {
      return "/" + url_nav.url_parameters;
    }
  };

  const verifyUserAutenticate = () => {
    if (profile) {
      if (profile.Telefono != null && profile.Telefono !== "") {
        return true;
      }
    }
    return false;
  };
  const cerrarVentana = (valor) => {
    setOpenModalUserRegister(false);
  };

  const abrirDirecciones = (close) => {
    if (verifyUserAutenticate()) {
      startAddress();
    } else {
      setOpenModalUserRegister(true);
    }
    close();
  };

  const [openMobileMenu, setOpenMobileMenu] = React.useState(false);
  const toggleMobileMenu = () => {
    setOpenMobileMenu((prevState) => !prevState);
  };

  useEffect(() => {
    if (width >= 768 && openMobileMenu) {
      toggleMobileMenu();
    }
  }, [width]);

  const cartProductsCount = useMemo(() => {
    if (!list_carrito) return null;

    const subPedidos = [...list_carrito.SubPedidos];
    const filteredSubPedidos = subPedidos.filter(
      (subPedido) =>
        !(
          subPedido.IdMarket === sucursal.IdMarket &&
          subPedido.Sala.Id === sucursal.IdSucursal
        )
    );
    return filteredSubPedidos.reduce((total, subPedido) => {
      const totalProductos = subPedido.Productos.length;
      return total + totalProductos;
    }, 0) + carrito.length;
  }, [list_carrito, carrito, sucursal]);

  return (
    <>
      <Alert></Alert>
      {openModalUserRegister && (
        <RegistroInit setCloseModal={cerrarVentana}></RegistroInit>
      )}

      <Popover className="py-2">
        {({ open }) => (
          <>
            <div
              className="absolute inset-0 z-10 pointer-events-none"
              aria-hidden="true"
            />
            <div className="relative z-400 bg-white">
              <div
                className="flex gap-1 justify-between items-center sm:px-4 lg:px-6 md:justify-start"
                style={{ paddingLeft: "0.25rem", paddingRight: "0.25rem" }}
              >
                <div
                  className="cursor-pointer transition duration-200 ease-in-out transform hover:scale-105"
                  onClick={() => ir_inicio()}
                >
                  <span className="sr-only">Workflow</span>
                  <img
                    className=" h-6 md:h-16 w-auto"
                    src={logo}
                    alt="Hipermaxi"
                  />
                </div>

                <div className="flex flex-row  gap-2">
                  <NavbarMarket openSucursalesModal={openModal} />
                </div>
                <div className="flex items-center ml-auto gap-2 md:hidden">
                  <button
                    onClick={toggleMobileMenu}
                    className="text-black flex text-xs font-semibold justify-center items-center gap-1 max-w-24 min-[400px]:max-w-32 md:max-w-48 hover:underline"
                  >
                    <FontAwesomeIcon
                      icon={faUser}
                      style={{ fontSize: "24px", color: "#0349AB" }}
                    />

                    {verifyUserAutenticate() ? (
                      <span className="truncate">
                        {profile?.Nombres || "Sin nombre"}{" "}
                        {profile?.ApellidoPaterno}
                      </span>
                    ) : (
                      <span>Iniciar sesión</span>
                    )}

                    <FontAwesomeIcon icon={faAngleDown} size="sm" />
                  </button>
                  <div
                    className={classNames(
                      "relative",
                      "self-center",
                      "carrito_movil",
                      { "cursor-pointer": !cambio_detalle_carrito }
                    )}
                    onClick={() =>
                      !cambio_detalle_carrito ? startCarrito() : {}
                    }
                  >
                    <FontAwesomeIcon
                      icon={faCartShopping}
                      className="relative icon_cotificacion"
                      style={{ fontSize: "24px", color: "#0349AB" }}
                    ></FontAwesomeIcon>
                    {!!cartProductsCount && cartProductsCount > 0 && (
                      <div
                        className="absolute top-[-20%] left-[50%] w-4 h-4 p-1 text-xs flex justify-center items-center rounded-full"
                        style={{ backgroundColor: "#DC842D" }}
                      >
                        <span className="text-white">{cartProductsCount}</span>
                      </div>
                    )}
                  </div>
                </div>
                {/* desktop   */}
                <div className="hidden ml-0 md:flex-1 md:flex md:items-center md:justify-between md:gap-2 self-end">
                  <div className="hidden lg:flex lg:w-full">
                    {IdCategoria ? (
                      <BuscadorPorCategoria
                        categoriaId={IdCategoria}
                        subCategoriaId={subcategoriaId}
                      />
                    ) : (
                      <BuscadorGeneral />
                    )}
                  </div>
                  <div className="flex items-center ml-auto gap-2">
                    <Popover className="relative">
                      {({ open }) => (
                        <>
                          <Popover.Button className="text-black focus:outline-none flex gap-1 items-center hover:underline text-sm max-w-24 min-[400px]:max-w-32 md:max-w-48 min-w-[8rem]">
                            <FontAwesomeIcon
                              icon={faUser}
                              style={{ fontSize: "30px", color: "#0349AB" }}
                            />
                            {verifyUserAutenticate() ? (
                              <span className="truncate">
                                {profile?.Nombres || "Sin nombre"}{" "}
                                {profile?.ApellidoPaterno}
                              </span>
                            ) : (
                              <span>Iniciar sesión</span>
                            )}
                            <FontAwesomeIcon icon={faAngleDown} size="sm" />
                          </Popover.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Popover.Panel className="absolute bg-hiperorange_hover right-2 z-40 rounded-b-lg mt-[12px] shadow-2xl overflow-hidden">
                              {({ close }) => (
                                <div className=" w-full ">
                                  {verifyUserAutenticate() && (
                                    <div className="pl-10 pr-2 py-2 border-b-[1px] border-white">
                                      {open && (
                                        <HiperPuntosProfile
                                          nombre={profile.Nombres || ""}
                                          apellidoPaterno={
                                            profile.ApellidoPaterno || ""
                                          }
                                          profileCode={
                                            profile.CodigoCliente || ""
                                          }
                                          onCloseParentModal={close}
                                        />
                                      )}
                                    </div>
                                  )}
                                  <div className="mb-2">
                                    <ul className="list-none pl-0 divide-y divide-gray-800 divide-opacity-10">
                                      <li className="cursor-pointer relative  transition duration-200 ease-in-out transform hover:scale-105 hover:bg-hiperorange_hover_dark">
                                        <button
                                          onClick={async () => {
                                            abrirDirecciones(close);
                                          }}
                                          className="py-2 px-10 w-full flex items-center"
                                        >
                                          <FontAwesomeIcon
                                            icon={faMapLocationDot}
                                            className="text-white w-5"
                                          />
                                          <span className="text-white font-normal block truncate ml-2">
                                            Mis direcciones
                                          </span>
                                        </button>
                                      </li>
                                      <li className="cursor-pointer relative transition duration-200 ease-in-out transform hover:scale-105 hover:bg-hiperorange_hover_dark">
                                        <Link
                                          className="py-2 px-10 w-full flex items-center"
                                          onClick={() =>
                                            navigate_menu("mis-pedidos", close)
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon={faBagShopping}
                                            className="text-white w-5"
                                          />
                                          <span className="text-white font-normal block truncate ml-2">
                                            Mis pedidos
                                          </span>
                                        </Link>
                                      </li>
                                      <li className="cursor-pointer relative transition duration-200 ease-in-out transform hover:scale-105 hover:bg-hiperorange_hover_dark">
                                        <Link
                                          className="py-2 px-10 w-full flex items-center"
                                          onClick={() =>
                                            navigate_menu(
                                              "lista-compras",
                                              close
                                            )
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon={faHeart}
                                            className="text-white w-5"
                                          />
                                          <span className="text-white font-normal block truncate ml-2">
                                            Mis listas de compras
                                          </span>
                                        </Link>
                                      </li>
                                      <li className="cursor-pointer relative transition duration-200 ease-in-out transform hover:scale-105 hover:bg-hiperorange_hover_dark">
                                        <Link
                                          className="py-2 px-10 w-full flex items-center"
                                          onClick={() =>
                                            navigate_menu("cuenta", close)
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon={faUser}
                                            className="text-white w-5"
                                          />
                                          <span className="text-white font-normal block truncate ml-2">
                                            Mi cuenta
                                          </span>
                                        </Link>
                                      </li>
                                      {verHiperPuntosParameter?.Valor ===
                                        "1" && (
                                        <li className="cursor-pointer relative transition duration-200 ease-in-out transform hover:scale-105 hover:bg-hiperorange_hover_dark">
                                          <Link
                                            className="py-2 px-10 w-full flex items-center"
                                            onClick={() =>
                                              navigate_menu(
                                                "hiper-puntos",
                                                close
                                              )
                                            }
                                          >
                                            <FontAwesomeIcon
                                              icon={faSack}
                                              className="text-white w-5"
                                            />
                                            <span className="text-white font-normal block truncate ml-2">
                                              Hiper puntos
                                            </span>
                                          </Link>
                                        </li>
                                      )}
                                      <li className="cursor-pointer relative transition duration-200 ease-in-out transform hover:scale-105 hover:bg-hiperorange_hover_dark">
                                        <Link
                                          className="py-2 px-10 w-full flex items-center"
                                          onClick={() =>
                                            navigate_menu("mis-tarjetas", close)
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon={faCreditCard}
                                            className="text-white w-5"
                                          />
                                          <span className="text-white font-normal block truncate ml-2">
                                            Mis tarjetas
                                          </span>
                                        </Link>
                                      </li>
                                      {profileAux &&
                                        verifyUserAutenticate() &&
                                        open && (
                                          <li className="cursor-pointer relative transition duration-200 ease-in-out transform hover:scale-105 hover:bg-hiperorange_hover_dark">
                                            <Notificacion
                                              onCloseModal={close}
                                            />
                                          </li>
                                        )}
                                      <li className="cursor-pointer relative transition duration-200 ease-in-out transform hover:scale-105 hover:bg-hiperorange_hover_dark">
                                        {verifyUserAutenticate() ? (
                                          <button
                                            // to={"/"}
                                            // reloadDocument
                                            className="py-2 px-10 w-full flex items-center"
                                            onClick={(e) => {
                                              logout(e, close);
                                              // close();
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon={faRightFromBracket}
                                              className="text-white w-5"
                                            />
                                            <span className="text-white font-normal block truncate ml-2">
                                              {cerrandoSesion
                                                ? "Cerrando sesión"
                                                : "Cerrar sesión"}
                                            </span>
                                          </button>
                                        ) : (
                                          <button
                                            className="py-2 px-10 w-full flex items-center"
                                            onClick={() => {
                                              iniciar_session();
                                              close();
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon={faRightFromBracket}
                                              className="text-white w-5"
                                            />
                                            <span className="text-white font-normal block truncate ml-2">
                                              Iniciar sesión{" "}
                                            </span>
                                          </button>
                                        )}
                                      </li>
                                    </ul>
                                  </div>

                                  <div className="my-0">
                                    <Link
                                      to={
                                        "https://api.whatsapp.com/send?phone=" +
                                        numerocel +
                                        "&text=Hola!"
                                      }
                                      target="_blank"
                                      onClick={close}
                                    >
                                      <ButtonIcon
                                        icon={faHeadset}
                                        label="Atención al cliente"
                                        className={
                                          "bg-hiperblue hover:bg-hiperblue_hover text-white px-1 justify-content-center rounded-t-none w-full transition duration-200 ease-in-out transform hover:scale-105"
                                        }
                                        onClick={() => {}}
                                      />
                                    </Link>
                                  </div>
                                </div>
                              )}
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                    <div
                      className={classNames("relative", {
                        "cursor-pointer": !cambio_detalle_carrito,
                      })}
                      onClick={() =>
                        !cambio_detalle_carrito ? startCarrito() : {}
                      }
                    >
                      <FontAwesomeIcon
                        icon={faCartShopping}
                        style={{ fontSize: "30px", color: "#0349AB" }}
                      ></FontAwesomeIcon>
                      {!!cartProductsCount && cartProductsCount > 0 && (
                        <div
                          className="absolute top-[-20%] left-[50%] w-4 h-4 p-2.5  flex justify-center items-center rounded-full"
                          style={{ backgroundColor: "#DC842D" }}
                        >
                          <span className="text-white">
                            {cartProductsCount}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/*end desktop   */}
              </div>
            </div>

            <Drawer
              open={openMobileMenu}
              onClose={toggleMobileMenu}
              direction="right"
            >
              <div className="h-full py-2 shadow-lg ring-1 ring-black ring-opacity-5 bg-hiperorange_hover divide-y-2 divide-gray-50 overflow-hidden">
                <div className=" w-full">
                  {verifyUserAutenticate() && (
                    <div className="pl-6 pr-2 py-2 border-b-[1px] border-white">
                      {openMobileMenu && (
                        <HiperPuntosProfile
                          nombre={profile.Nombres || ""}
                          apellidoPaterno={profile.ApellidoPaterno || ""}
                          profileCode={profile.CodigoCliente || ""}
                          onCloseParentModal={toggleMobileMenu}
                        />
                      )}
                    </div>
                  )}
                  <div className="mb-11">
                    <ul className="list-none pl-0 divide-y divide-gray-800 divide-opacity-10">
                      <li className="cursor-pointer relative transition duration-200 ease-in-out transform hover:scale-105 hover:bg-hiperorange_hover_dark">
                        <button
                          className="py-2 px-4 w-full flex items-center"
                          onClick={() => abrirDirecciones(toggleMobileMenu)}
                        >
                          <FontAwesomeIcon
                            icon={faMapLocationDot}
                            className="text-white w-5"
                          />
                          <span className="text-white font-normal block truncate ml-2">
                            Mis direcciones
                          </span>
                        </button>
                      </li>
                      <li className="cursor-pointer relative transition duration-200 ease-in-out transform hover:scale-105 hover:bg-hiperorange_hover_dark">
                        <Link
                          className="py-2 px-4 w-full flex items-center"
                          onClick={() =>
                            navigate_menu("mis-pedidos", toggleMobileMenu)
                          }
                        >
                          <FontAwesomeIcon
                            icon={faBagShopping}
                            className="text-white w-5"
                          />
                          <span className="text-white font-normal block truncate ml-2">
                            Mis pedidos
                          </span>
                        </Link>
                      </li>
                      <li className="cursor-pointer relative transition duration-200 ease-in-out transform hover:scale-105 hover:bg-hiperorange_hover_dark">
                        <Link
                          className="py-2 px-4 w-full flex items-center"
                          onClick={() =>
                            navigate_menu("lista-compras", toggleMobileMenu)
                          }
                        >
                          <FontAwesomeIcon
                            icon={faHeart}
                            className="text-white w-5"
                          />
                          <span className="text-white font-normal block truncate ml-2">
                            Mis listas de compras
                          </span>
                        </Link>
                      </li>
                      <li className="cursor-pointer relative transition duration-200 ease-in-out transform hover:scale-105 hover:bg-hiperorange_hover_dark">
                        <Link
                          className="py-2 px-4 w-full flex items-center"
                          onClick={() =>
                            navigate_menu("cuenta", toggleMobileMenu)
                          }
                        >
                          <FontAwesomeIcon
                            icon={faUser}
                            className="text-white w-5"
                          />
                          <span className="text-white font-normal block truncate ml-2">
                            Mi cuenta
                          </span>
                        </Link>
                      </li>
                      {verHiperPuntosParameter?.Valor === "1" && (
                        <li className="cursor-pointer relative transition duration-200 ease-in-out transform hover:scale-105 hover:bg-hiperorange_hover_dark">
                          <Link
                            className="py-2 px-4 w-full flex items-center"
                            onClick={() =>
                              navigate_menu("hiper-puntos", toggleMobileMenu)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faSack}
                              className="text-white w-5"
                            />
                            <span className="text-white font-normal block truncate ml-2">
                              Hiper puntos
                            </span>
                          </Link>
                        </li>
                      )}

                      <li className="cursor-pointer relative transition duration-200 ease-in-out transform hover:scale-105 hover:bg-hiperorange_hover_dark">
                        <Link
                          className="py-2 px-4 w-full flex items-center"
                          onClick={() =>
                            navigate_menu("mis-tarjetas", toggleMobileMenu)
                          }
                        >
                          <FontAwesomeIcon
                            icon={faCreditCard}
                            className="text-white w-5"
                          />
                          <span className="text-white font-normal block truncate ml-2">
                            Mis tarjetas
                          </span>
                        </Link>
                      </li>
                      {profileAux &&
                        verifyUserAutenticate() &&
                        openMobileMenu && (
                          <li className="cursor-pointer relative transition duration-200 ease-in-out transform hover:scale-105 hover:bg-hiperorange_hover_dark">
                            <Notificacion
                              onCloseModal={toggleMobileMenu}
                              isMobile
                            />
                          </li>
                        )}
                      <li className="cursor-pointer relative transition duration-200 ease-in-out transform hover:scale-105 hover:bg-hiperorange_hover_dark">
                        {verifyUserAutenticate() ? (
                          <button
                            // to={"/"}
                            // reloadDocument
                            className="py-2 px-4 w-full flex items-center"
                            onClick={(e) => {
                              logout(e, toggleMobileMenu);
                              // toggleMobileMenu();
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faRightFromBracket}
                              className="text-white w-5"
                            />
                            <span className="text-white font-normal block truncate ml-2">
                              {cerrandoSesion
                                ? "Cerrando sesión"
                                : "Cerrar sesión"}
                            </span>
                          </button>
                        ) : (
                          <button
                            className="py-2 px-4 w-full flex items-center"
                            onClick={() => {
                              iniciar_session();
                              toggleMobileMenu();
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faRightFromBracket}
                              className="text-white w-5"
                            />
                            <span className="text-white font-normal block truncate ml-2">
                              Iniciar sesión
                            </span>
                          </button>
                        )}
                      </li>
                      <li className="cursor-pointer relative pt-4">
                        <Link
                          to={
                            "https://api.whatsapp.com/send?phone=" +
                            numerocel +
                            "&text=Hola!"
                          }
                          target="_blank"
                          onClick={toggleMobileMenu}
                        >
                          <ButtonIcon
                            icon={faHeadset}
                            label="Atención al cliente"
                            className={
                              "bg-hiperblue hover:bg-hiperblue_hover text-white px-1 justify-content-center w-full rounded-none  transition duration-200 ease-in-out transform hover:scale-105"
                            }
                            onClick={() => {}}
                          />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Drawer>
          </>
        )}
      </Popover>

      <Modal
        isOpen={modalIsOpen}
        appElement={document.getElementById("root")}
        onRequestClose={closeModal}
        className="fixed inset-0 flex items-center justify-center p-2 md:p-4 max-w-lg w-full md:w-auto md:max-w-3xl mx-auto focus-visible:outline-none"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-30"
        contentLabel="Example Modal"
        key={"sdfsdg"}
      >
        <Sucursal onCloseModal={closeModal} />
      </Modal>

      <Modal
        isOpen={modalAddress}
        ariaHideApp={false}
        onAfterOpen={afterOpenModal}
        onRequestClose={endAddress}
        className="fixed inset-0 flex items-center justify-center p-2 md:p-4 w-full md:w-[25rem] mx-auto focus-visible:outline-none"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-30"
        contentLabel="address"
      >
        <Direccion close_modal={endAddress} />
      </Modal>
      <Modal
        isOpen={!!direccion && openModalCarrito === "1"}
        ariaHideApp={false}
        onAfterOpen={afterOpenModal}
        onRequestClose={cerraModalCarrito}
        className="fixed inset-0 flex items-center justify-center p-2 md:p-4 max-w-lg w-full md:max-w-[1320px] md:mx-auto focus-visible:outline-none"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-30"
        contentLabel="Carrito de compras"
        id="navbarCart"
      >
        <MiCarrito
          cerrarModal={endCarrito}
          cerrarModalAnonimo={endCarritoAnonimo}
        />
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  sucursal: state.BranchOffice.sucursal,
  carrito: state.Carrito.carrito,
  cambio_carrito: state.Carrito.cambio_carrito,
  respuesta_carrito_anonimo: state.Carrito.respuesta_carrito_anonimo,
  respuesta_carrito_user: state.Carrito.respuesta_carrito_user,
  list_carrito: state.Carrito.list_carrito,
  direccion: state.Location.direccion,
  locations: state.Location.locations,
  categorias: state.Home.categorias,
  category: state.Home.category,
  profile: state.Profile.profile,
  url_navigation: state.UrlNavegacion.url_navigation,
  show_alert: state.Swalerts.show_alert,
  redirect: state.Swalerts.redirect,
  cambio_detalle_carrito: state.Carrito.cambio_detalle_carrito,
  producto_delete: state.Carrito.producto_delete,
  verHiperPuntosParameter: state.HiperPuntos.verHiperPuntosParameter,
});

export default connect(mapStateToProps, {
  store_carrito_data,
  update_carrito,
  limpiar_perfil,
  limpiar_auth,
  limpiar_direccion,
  limpiar_sucursal,
  limpiar_carrito,
  clear_pedidos,
  cleadSwAlert,
  limpiar_estado_anonimo,
  limpiar_data_lista_compra,
  get_ubicacion,
  get_lista_carrito_compras,
  get_carrito_por_sucursal,
  get_sucursales_cerca,
  get_anonimo_token,
})(Navbar);
