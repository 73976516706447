// ZoomedImage.jsx
import React, { useContext } from "react";
import { ZoomContext } from "./ZoomContext";
import { useImageCache } from "../../hooks/useImageCache";

const ZoomedImage = () => {
  const { zoomData } = useContext(ZoomContext);
  // const { imageSrc } = useImageCache({ src: zoomData.highResImageSrc });
  if (!zoomData.showZoom) {
    return null;
  }

  return (
    <div
      className={`absolute top-0 left-0 z-50 w-full h-full bg-white ${zoomData.showZoom && !!zoomData.highResImageSrc ? "opacity-100" : "opacity-0"} flex justify-center items-center shadow-md border rounded-md`}
      style={{
        backgroundImage: `url(${zoomData.highResImageSrc})`,
        backgroundPosition: zoomData.backgroundPosition,
        backgroundSize: `${zoomData.zoomFactor * 100}%`,
      }}
    />
  );
};

export default ZoomedImage;
