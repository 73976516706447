import {
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,
  ACTIVATION_SUCCESS,
  ACTIVATION_FAIL,
  SET_AUTH_LOADING,
  REMOVE_AUTH_LOADING,
  LOGIN_SUCCESS,
  AUTHENTICATED_SUCCESS,
  AUTHENTICATED_FAIL,
  REFRESH_SUCCESS,
  REFRESH_FAIL,
  ACTIVACION_ANONIMO_SUCCESS,
  PREFIJO_SUCCESS,
  DEPARTAMENTO_SUCCESS,
  PREFIJO_FAIL,
  DEPARTAMENTO_FAIL,
  CLEAR_AUTH,
  CODE_ACCESS_EXTERM_SUCCESS,
  CODE_ACCESS_EXTERM_FAILS,
  SET_SOCIAL_USER_DATA,
} from "./types";
import axios from "axios";
import { setSwAlert, showRespuesta } from "./swalert";
import { start_peticion, end_peticion } from "./peticion";
import storage from "../../components/Utils/storage";
import respuestas from "../../components/Alertas";
import { info_session } from "./profile";

let grant_type = process.env.REACT_APP_GRANT_TYPE;
let aplicacion = process.env.REACT_APP_APLICACION;
let cuenta = process.env.REACT_APP_CUENTA;
let password = process.env.REACT_APP_PASSWORD;
let autologin = process.env.REACT_APP_AUTO_LOGIN;

export const get_prefijos = () => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/markets/prefijos`,
      null,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: PREFIJO_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: PREFIJO_FAIL,
      });

      dispatch(showRespuesta(res));
    }
  } catch (err) {
    //

    dispatch({
      type: PREFIJO_FAIL,
    });

    dispatch(setSwAlert(respuestas.peticionError, true, 500));
  }
};
export const get_departamento = () => async (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/markets/pais/ciudades`,
      config
    );
    if (res.status === 200) {
      dispatch({
        type: DEPARTAMENTO_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: DEPARTAMENTO_FAIL,
      });

      dispatch(showRespuesta(res));
    }
  } catch (err) {
    dispatch({
      type: DEPARTAMENTO_FAIL,
    });

    dispatch(setSwAlert(respuestas.peticionError, true, 500));
  }
};
export const check_authenticated = () => async (dispatch) => {
  if (localStorage.getItem("access_token")) {
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      token: localStorage.getItem("access_token"),
    });

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/Info-Sesion`,
        body,
        config
      );

      if (res.status === 200) {
        dispatch({
          type: AUTHENTICATED_SUCCESS,
        });
      } else {
        dispatch({
          type: AUTHENTICATED_FAIL,
        });
        dispatch(showRespuesta(res));
      }
    } catch (err) {
      dispatch({
        type: AUTHENTICATED_FAIL,
      });

      dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

// 01
export const codigo_acceso =
  (telefono, prefijo, TipoEnvioAcceso) => async (dispatch) => {
    dispatch(start_peticion("Enviando código"));
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({
      telefono,
      prefijo,
      TipoEnvioAcceso,
    });

    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/Codigo-Acceso`,
        body,
        config
      );
      if (res.status === 200 && res.data.Estado === 200) {
        localStorage.setItem("phone", telefono);
        localStorage.setItem("prefijo", prefijo);
        localStorage.setItem("tipoEnvicoAcceso", TipoEnvioAcceso);
        res.data.phone = telefono;
        dispatch({
          type: SIGNUP_SUCCESS,
          payload: res.data,
        });
        dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 200));
      } else {
        dispatch({
          type: SIGNUP_FAIL,
        });
        dispatch(showRespuesta(res));
      }
      dispatch(end_peticion());
    } catch (err) {
      dispatch({
        type: SIGNUP_FAIL,
      });
      dispatch(end_peticion());
      dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }
  };
export const codigo_acceso_externo =
  (token, TipoAcceso) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({
      token,
      TipoAcceso,
    });

    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/codigo-acceso-por-externo2`,
        body,
        config
      );

      if (res.status === 200 && res.data.Estado === 200) {
        dispatch({
          type: CODE_ACCESS_EXTERM_SUCCESS,
          payload: res.data,
        });
        dispatch(get_token(token, res.data.Dato));
        dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 200));
        return true;
      } else {
        dispatch({
          type: CODE_ACCESS_EXTERM_FAILS,
        });
        // dispatch(showRespuesta(res));
      }
      dispatch(end_peticion());
      return false;
    } catch (err) {
      dispatch({
        type: CODE_ACCESS_EXTERM_FAILS,
      });
      dispatch(end_peticion());
      dispatch(setSwAlert(respuestas.peticionError, true, 500));
      throw new Error(err);
    }
  };
export const set_token_externo = (Token, TipoAcceso) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };
  const body = JSON.stringify({
    Token,
    TipoAcceso,
  });

  try {
    if (Token === null) {
      return;
    }
    const res = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/token-externo`,
      body,
      config
    );
    if (res.status === 200 && res.data.Estado === 200) {
      /*  dispatch({
                  type: CODE_ACCESS_EXTERM_SUCCESS,
                  payload: res.data
              });
              dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 200));
              */
      return true;
    } else {
      /*   dispatch({
                   type: CODE_ACCESS_EXTERM_FAILS
               });
               dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 400));
               */
      dispatch({
        type: CODE_ACCESS_EXTERM_FAILS,
      });
      dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 400));

      // dispatch(showRespuesta(res));
    }
    dispatch(end_peticion());
    return false;
  } catch (err) {
    dispatch({
      type: CODE_ACCESS_EXTERM_FAILS,
    });
    dispatch(end_peticion());
    dispatch(setSwAlert(respuestas.peticionError, true, 500));
    return false;
    // dispatch(setAlert('Error al conectar con el servidor, intenta mas tarde.', 'red'));
  }
};

export const activate = (username, CodigoAcceso) => async (dispatch) => {
  dispatch(start_peticion("activando..."));
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
    },
  };

  let user = JSON.parse(username);
  let prefijo = storage.get("prefijo");
  const body = {
    grant_type: grant_type,
    aplicacion: aplicacion,
    cuenta: cuenta,
    username: user,
    CodigoAcceso: CodigoAcceso,
    Autologin: autologin,
    Prefijo: prefijo ? prefijo : null,
  };

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/token`,
      body,
      config
    );
    if (res.status === 200) {
      storage.set("phone", username);
      storage.set("codigo_acceso", CodigoAcceso);
      storage.set("user", "usuario");
      storage.set("reg_phone", "si");
      storage.remove("prefijo");

      dispatch({
        type: ACTIVATION_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: ACTIVATION_FAIL,
      });
      dispatch(showRespuesta(res));
    }
    dispatch(end_peticion());
  } catch (err) {
    if (typeof err.response !== undefined && err !== null) {
      dispatch(setSwAlert(err.response.data.error_description, true, 400));
    } else {
      dispatch(setSwAlert(null, true, 400));
    }

    dispatch({
      type: ACTIVATION_FAIL,
    });
    dispatch(end_peticion());
    dispatch(setSwAlert(respuestas.peticionError, true, 500));
  }
};
export const activate_sin_autologin =
  (username, CodigoAcceso) => async (dispatch) => {
    dispatch(start_peticion("activando..."));
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
      },
    };
    let user = JSON.parse(username);
    let prefijo = storage.get("prefijo");

    const body = {
      grant_type: grant_type,
      aplicacion: aplicacion,
      cuenta: cuenta,
      username: user,
      CodigoAcceso: CodigoAcceso,
      Prefijo: prefijo ? prefijo : null,
    };

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/token`,
        body,
        config
      );
      if (res.status === 200) {
        storage.set("phone", username);
        storage.set("codigo_acceso", CodigoAcceso);
        storage.set("user", "usuario");
        storage.set("reg_phone", "si");
        storage.remove("prefijo");
        dispatch({
          type: ACTIVATION_SUCCESS,
          payload: res.data,
        });
        dispatch(setSwAlert("Código activado correctamente", false, 200));
      } else {
        dispatch({
          type: ACTIVATION_FAIL,
        });
        dispatch(showRespuesta(res));
      }
      dispatch(end_peticion());
    } catch (err) {
      if (typeof err.response !== undefined && err !== null) {
        dispatch(setSwAlert(err.response.data.error_description, true, 400));
      } else {
        dispatch(setSwAlert(null, true, 400));
      }

      dispatch({
        type: ACTIVATION_FAIL,
      });
      dispatch(end_peticion());
      dispatch(setSwAlert(respuestas.peticionError, true, 500));
      throw err;
    }
  };
export const get_token = (Token, CodigoAcceso) => async (dispatch) => {
  dispatch(start_peticion("activando..."));
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
    },
  };
  let prefijo = storage.get("prefijo");

  const body = {
    grant_type: grant_type,
    aplicacion: aplicacion,
    cuenta: cuenta,
    CodigoAcceso: CodigoAcceso,
    Token: Token,
    Prefijo: prefijo ? prefijo : null,
  };

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/token`,
      body,
      config
    );
    if (res.status === 200) {
      /* storage.set('phone', username);
             storage.set('codigo_acceso', CodigoAcceso);
             */
      storage.set("user", "usuario");
      storage.remove("prefijo");
      dispatch({
        type: ACTIVATION_SUCCESS,
        payload: res.data,
      });
      dispatch(info_session(res.data.access_token));
    } else {
      dispatch({
        type: ACTIVATION_FAIL,
      });
      dispatch(showRespuesta(res));
    }
    dispatch(end_peticion());
  } catch (err) {
    /* if (typeof (err.response) !== undefined && err !== null) {

            dispatch(setSwAlert(err.response.data.error_description, true, 400));
        } else {
            dispatch(setSwAlert(null, true, 400));
        }
        */

    dispatch({
      type: ACTIVATION_FAIL,
    });
    dispatch(end_peticion());

    dispatch(setSwAlert(respuestas.peticionError, true, 500));
  }
};

export const limpiar_auth = () => async (dispatch) => {
  dispatch({
    type: CLEAR_AUTH,
  });
};

// 02
export const test_loading_token = (access_token) => async (dispatch) => {
  dispatch({
    type: SET_AUTH_LOADING,
  });

  //

  try {
    setTimeout(() => {
      if (access_token != null) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { access_token },
        });
        dispatch(setSwAlert("Cuenta activada correctamente", false, 200));
      } else {
        dispatch({
          type: ACTIVATION_FAIL,
        });
        dispatch(setSwAlert("Error activando cuenta", true, 400));
      }
      dispatch({
        type: REMOVE_AUTH_LOADING,
      });
    }, 2000);
  } catch (err) {
    dispatch({
      type: ACTIVATION_FAIL,
    });
    dispatch({
      type: REMOVE_AUTH_LOADING,
    });

    dispatch(
      setSwAlert(
        "Error al conectar con el servidor, intenta más tarde.",
        true,
        500
      )
    );
  }
};

//03
export const terminos_condiciones =
  (idTerminoCondicion) => async (dispatch) => {
    dispatch({
      type: SET_AUTH_LOADING,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };

    const url = `${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/terminos-condiciones/${idTerminoCondicion}/aceptar?=3`;

    try {
      const res = await axios.put(url, null, config);

      if (!res.data.ConError) {
        dispatch(info_session());
        return true;
      } else {
        //    dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 400));
        dispatch(showRespuesta(res));
      }
      dispatch({
        type: REMOVE_AUTH_LOADING,
      });
    } catch (err) {
      dispatch({
        type: ACTIVATION_FAIL,
      });
      dispatch({
        type: REMOVE_AUTH_LOADING,
      });

      //dispatch(setSwAlert('Error al conectar con el servidor, intenta mas tarde.', true, 500));

      dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }
  };

// autenticacion anonimo
export const get_anonimo_token = () => async (dispatch) => {
  dispatch(end_peticion());
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/Anonimo-Por-Token`,
      config
    );

    if (res.status === 200 && res.data.Estado === 200) {
      let CodigoAcceso = res.data.Dato.Codigo;
      let Token = res.data.Dato.Token;

      const configAcceso = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        },
      };
      let prefijo = storage.get("prefijo");

      const body = {
        grant_type: grant_type,
        aplicacion: aplicacion,
        cuenta: cuenta,
        password: password,
        CodigoAcceso: CodigoAcceso,
        Token: Token,
        Prefijo: prefijo ? prefijo : null,
      };
      const respuesta = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/token`,
        body,
        configAcceso
      );

      if (respuesta.status === 200) {
        storage.set("user", "anonimo");
        dispatch({
          type: ACTIVACION_ANONIMO_SUCCESS,
          payload: respuesta.data,
        });
      } else {
        dispatch({
          type: ACTIVATION_FAIL,
        });
        dispatch(
          setSwAlert(respuesta.data.Mensaje, respuesta.data.ConError, 400)
        );
      }
    } else {
      dispatch({
        type: SIGNUP_FAIL,
      });
      // dispatch(setAlert('Error activando cuenta', 'red'));
      //dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 400));
      dispatch(showRespuesta(res));
    }
    dispatch({
      type: REMOVE_AUTH_LOADING,
    });
  } catch (err) {
    dispatch({
      type: SIGNUP_FAIL,
    });
    dispatch({
      type: REMOVE_AUTH_LOADING,
    });
    dispatch(setSwAlert(respuestas.peticionError, true, 500));
  }
};

export const refresh = () => async (dispatch) => {
  let username = storage.get("phone");
  let codigo_acceso = storage.get("codigo_acceso");
  let user = storage.get("user");

  //COMENTADO TEMPORALMENTE

  if (username && codigo_acceso) {
    dispatch({
      type: SET_AUTH_LOADING,
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
      },
    };
    let prefijo = storage.get("prefijo");

    const body = {
      grant_type: grant_type,
      aplicacion: aplicacion,
      cuenta: cuenta,
      username: username,
      CodigoAcceso: codigo_acceso,
      Autologin: autologin,
      Prefijo: prefijo ? prefijo : null,
    };

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/token`,
        body,
        config
      );

      if (res.status === 200) {
        dispatch({
          type: REFRESH_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: REFRESH_FAIL,
        });
        dispatch(showRespuesta(res));
      }
    } catch (err) {
      dispatch({
        type: REFRESH_FAIL,
      });

      dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }
  } else {
    if (user === "usuario") {
      dispatch({
        type: REFRESH_FAIL,
      });
    }
  }
};

export const reset_activate_sin_autologin = () => (dispatch) => {
  dispatch({
    type: SIGNUP_FAIL,
  });
};

export const set_social_user_data =
  ({ socialUserId, socialTipoAcceso, socialEmail }) =>
  (dispatch) => {
    dispatch({
      type: SET_SOCIAL_USER_DATA,
      payload: { socialUserId, socialTipoAcceso, socialEmail },
    });
  };

export const reset_social_user_data = () => (dispatch) => {
  dispatch({
    type: SET_SOCIAL_USER_DATA,
    payload: null,
  });
};
