import { useMemo } from "react";
import { useGetCurrentUrl } from "./useGetCurrentUrl";
import slug from "../components/Utils/slug";

export function useBreadcrumbs({ categoria, subcategoria, producto }) {
  const { sucursalBaseUrl } = useGetCurrentUrl();
  const categoriaListItem = useMemo(() => {
    if (!categoria || categoria == null) return null;
    return {
      position: 2,
      name: categoria,
      item: `${sucursalBaseUrl}/categoria/${slug.data_slug(categoria)}`,
    };
  }, [categoria, sucursalBaseUrl]);

  const subcategorialListItem = useMemo(() => {
    if (!subcategoria || subcategoria == null) return null;
    return {
      position: 3,
      name: subcategoria,
      item: `${sucursalBaseUrl}/categoria/${slug.data_slug(
        categoria
      )}/${slug.data_slug(subcategoria)}`,
    };
  }, [categoria, subcategoria, sucursalBaseUrl]);

  const productoListItem = useMemo(() => {
    if (!producto) return null;

    let itemPath = `${sucursalBaseUrl}`;

    if (categoria) {
      itemPath += `/categoria/${slug.data_slug(categoria)}`;
      if (subcategoria) {
        itemPath += `/${slug.data_slug(subcategoria)}`;
      }
    }

    itemPath += `/${slug.data_slug(producto)}`;

    return {
      position: 4,
      name: producto,
      item: itemPath,
    };
  }, [categoria, subcategoria, producto, sucursalBaseUrl]);

  const breadcrumbs = [
    {
      position: 1,
      name: "Inicio",
      item: sucursalBaseUrl,
    },
  ];

  if (categoriaListItem) breadcrumbs.push(categoriaListItem);
  if (subcategorialListItem) breadcrumbs.push(subcategorialListItem);
  if (productoListItem) breadcrumbs.push(productoListItem);

  return { breadcrumbs };
}
