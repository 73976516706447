import axios from "axios";

export const getByConfig = async (keyConfig) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/configparametro/obtener-por-keyconfig?keyConfig=${keyConfig}`,
      {
        ...config,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching config:", error);
    throw error;
  }
};
