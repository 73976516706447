import { DEFAULT_CURRENCY } from "../use-track-events";

function getTotal(pedidos: any[]) {
  return pedidos.reduce((total, pedido) => {
    // Extraer el valor numérico de CostoEnvio eliminando todo excepto los dígitos y el punto decimal
    const costoEnvio = parseFloat(pedido.CostoEnvio?.replace("Bs. ", ""));
    // Sumar ImporteSubtotal y el valor numérico de CostoEnvio para este pedido
    const totalPedido =
      pedido.ImporteSubtotal + (isNaN(costoEnvio) ? 0 : costoEnvio);
    // Acumular el total
    return total + totalPedido;
  }, 0);
}

function getProducts(pedidos: any[]) {
  return pedidos.flatMap((pedido: any) =>
    pedido.Productos.map((producto: any) => {
      let cantidad = producto.Cantidad;
      if (producto.Pesos) {
        cantidad = producto.Pesos.reduce((total: any, producto: any) => {
          const cantidadProducto = producto.Piezas * producto.PesoAproxMax;
          return total + cantidadProducto;
        }, 0);
      }
      return {
        item_id: producto.IdProducto,
        item_name: producto.Descripcion,
        quantity: cantidad,
        price: producto.ConOferta
          ? producto.PrecioOferta
          : producto.PrecioVenta,
        affiliation: pedido.Sala.Descripcion,
      };
    })
  );
}

export function useBuildTrackingPurchaseDataForGoogleAnalytics() {
  const buildTrackingPurchaseDataForGoogleAnalytics = (
    pedidos: any[],
    transaction_id: string,
    shipping: number
  ) => {
    const data = {
      currency: DEFAULT_CURRENCY,
      value: getTotal(pedidos),
      // transaction_id: new Date().getTime(),
      items: getProducts(pedidos),
      shipping
    };

    return data;
  };

  return { buildTrackingPurchaseDataForGoogleAnalytics };
}
