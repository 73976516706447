import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import {
  faChevronRight
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import slug from "../../components/Utils/slug";
import { LazyLoadImage } from "../../components/lazyLoadImage";

export default function ListaSucursal({
  branch_office,
  onClick,
  ciudad,
  backLink,
}) {
  const getUrl = (item_sucursal) => {
    let sucursal_select = item_sucursal;
    let parametro_url = {
      ciudad: ciudad ? slug.data_slug(ciudad.Nombre) : "",
      sucursal: slug.data_slug(sucursal_select.Descripcion),
    };
    let resp = parametro_url.ciudad + " " + parametro_url.sucursal;
    let new_url = slug.data_barra(resp);

    let url_parameters = new_url + backLink;
    return "/" + url_parameters;
  };
  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-1">
        <div className="font-bold flex-1">{branch_office.Descripcion}</div>
        {branch_office.distancia && <div>{branch_office.distancia} km</div>}
      </div>
      <div className="grid md:grid-cols-2 gap-2 flex-grow">
        {branch_office.Locatarios.map((locatario, index) => {
          const url = getUrl(locatario);
          return (
            <div
              key={`locatario-${locatario.IdMarket}-${locatario.IdSucursal}-${index}`}
              className={`text-inherit flex gap-2 py-2 px-2.5 rounded-lg bg-gray-200 h-full`}
              id={locatario.IdSucursal}
            >
              <div className="flex flex-col gap-2 flex-grow text-black">
                <a
                  href={url}
                  onClick={(e) => {
                    e.preventDefault();
                    onClick(locatario);
                  }}
                  className="flex gap-1 items-center text-inherit hover:underline"
                >
                  <div className="w-6 h-6 relative">
                    <LazyLoadImage
                      className="w-6 h-6"
                      width={24}
                      height={24}
                      src={locatario.TipoServicio.UrlFoto}
                      alt=""
                    />
                  </div>
                  <div className="flex-1 text-left text-sm font-semibold">
                    {locatario.TipoServicio.Descripcion}
                  </div>
                  <FontAwesomeIcon icon={faChevronRight} size="sm" />
                </a>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${locatario.Latitud},${locatario.Longitud}`}
                  className="flex gap-1 items-center text-inherit hover:underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  {/* <div className="w-6 h-6 bg-gray-600 flex items-center justify-center rounded-full">
                    <FontAwesomeIcon
                      icon={faMapLocationDot}
                      className="text-white w-4"
                    />
                  </div> */}

                  <div className="flex-1 text-left text-sm">
                    {locatario.Direccion}
                  </div>
                </a>
                <a
                  href={
                    "https://api.whatsapp.com/send?phone=" +
                    locatario?.ContactoWhatsapp +
                    "&text=Hola!"
                  }
                  className="flex gap-2 items-center text-inherit hover:underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="w-6 h-6 bg-[#128C7E] flex items-center justify-center rounded-full">
                    <FontAwesomeIcon
                      icon={faWhatsapp}
                      className="text-white w-4"
                    />
                  </div>
                  <div className="flex-1 text-left text-sm">
                    {locatario?.ContactoWhatsapp.replace(/591(\d+)/, "+591 $1")}
                  </div>
                </a>
                {/* <a
                  href={
                    "https://api.whatsapp.com/send?phone=" +
                    locatario?.ContactoWhatsapp +
                    "&text=Hola!"
                  }
                  className="flex gap-1 items-center text-inherit hover:underline pl-6"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faTruckFast} className="w-6 text-gray-600" />
                  <div className="flex-1 text-left text-sm">
                    {locatario?.TipoEntregas?.map(item => item.Descripcion).join(', ')}
                  </div>
                </a> */}
              </div>

              {/* <a
                href={url}
                onClick={(e) => {
                  e.preventDefault();
                  onClick(locatario);
                }}
                className="text-inherit h-full flex items-center"
              >
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="h-[14px]"
                />
              </a> */}
            </div>
          );
        })}
      </div>
    </div>
  );
}
